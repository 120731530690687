var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState, useEffect } from 'react';
import ModalDefault from './ModalDefault';
import Button from '../button/Button';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CalendarPicker from '@mui/lab/CalendarPicker';
import { ModalContext } from '../../provider/ModalProvider';
import { setLocaleDateString } from '../../utils/tools';
import { styled, Box } from '@mui/material';
import { d_flex, d_flex_center, dir_column } from '../../styles/common';
import dayjs from 'dayjs';
var CustomDatePicker = styled(CalendarPicker)(function (_a) {
    var theme = _a.theme, view = _a.view;
    var year_style = {};
    var month_style = {};
    if (view === 'year') {
        year_style = {
            '& > div': {
                '&:first-of-type': {
                    display: 'none',
                },
                '&:last-of-type': {
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '& > div': {
                        '& > div': {
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            margin: '0',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            '& > div': {
                                width: '100%',
                                button: __assign(__assign({ height: '3.25rem', width: '100%', borderRadius: '0', margin: '0', padding: '1rem 0', fontSize: '1.2rem', fontWeight: '500' }, d_flex_center), { '&.Mui-selected': {
                                        backgroundColor: '#e0e0e0',
                                        color: theme.palette.black.main,
                                    } }),
                            },
                        },
                    },
                },
            },
        };
    }
    else if (view === 'month') {
        month_style = {
            '& > div': {
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                '&:first-of-type': {
                    position: 'relative',
                    marginTop: '15px',
                    '& > div': {
                        '&:first-of-type': {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            right: '0.95rem',
                            '& > div': {
                                '&:first-of-type': {
                                    width: '5rem',
                                    textAlign: 'center',
                                },
                            },
                            button: {
                                display: 'none',
                            },
                        },
                        '&:last-of-type': {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '0 3rem',
                            position: 'absolute',
                            top: '50%',
                            left: 0,
                            transform: 'translateY(-50%)',
                        },
                    },
                },
                '&:last-of-type': {
                    height: 'calc(100% - 30px)',
                    '& > div': {
                        height: '100%',
                        '& > div': {
                            height: '100%',
                            button: {
                                height: '25%',
                                margin: '0',
                                '&:hover': {
                                    borderRadius: '0',
                                },
                                '&:disabled': {
                                    color: '#cccccc',
                                },
                            },
                        },
                    },
                },
                '.PrivatePickersMonth-root.Mui-selected': {
                    // backgroundColor: '#FF453B',
                    borderRadius: '0',
                    backgroundColor: 'transparent',
                    color: 'rgba(0, 0, 0, 0.87)',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                },
            },
        };
    }
    return __assign(__assign(__assign({ '&.default_day': {
            '.MuiPickersDay-root': {
                '&.Mui-selected': {
                    backgroundColor: 'transparent',
                    color: 'rgba(0, 0, 0, 0.87)',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                },
            },
        }, '&.account_stat': {
            '.MuiPickersDay-root': {
                '&.Mui-selected': {
                    backgroundColor: '#FF453B',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: 'FF453B',
                    },
                },
            },
        }, width: '24rem', height: '25.714rem', '&::-webkit-scrollbar': {
            display: 'none',
        }, '& > div': {
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            fontSize: '1rem !important',
            '&:first-of-type': {
                position: 'relative',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                '& > div': {
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '&:first-of-type': {
                        position: 'relative',
                        right: '0.95rem',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        '& > div': {
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            '&:first-of-type': {
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                width: '5rem',
                                textAlign: 'center',
                            },
                        },
                        button: {
                            display: 'none',
                        },
                    },
                    '&:last-of-type': {
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0 0.5rem',
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        transform: 'translateY(-50%)',
                        '& > button': {
                            padding: '0',
                            width: '2.5rem',
                            height: '2.5rem',
                            '&:disabled': {
                                color: '#cccccc',
                            },
                        },
                    },
                },
            },
            '.PrivatePickersSlideTransition-root': {
                minHeight: '238px',
                height: '238px',
            },
            '&:last-of-type': {
                height: 'calc(100% - 30px)',
                '& > div': {
                    height: '100%',
                    '& > div:first-of-type': {
                        height: 'calc(20rem / 7)',
                        gap: '0.1rem 0rem',
                        '& > span': {
                            width: 'calc(22.5rem / 7)',
                            height: 'calc(18.75rem / 7)',
                            fontSize: '1rem',
                            margin: 0,
                        },
                    },
                    '& > div:last-of-type': {
                        height: 'calc(100% - 3rem)',
                        '& > div': {
                            '& > div': {
                                margin: '0',
                                gap: '0.1rem 0rem',
                                '& > div': {
                                    width: 'calc(22.5rem / 7)',
                                    height: 'calc(18.75rem / 7)',
                                    padding: '0 calc(1.875rem / 7)',
                                    '& > div': {
                                        width: 'calc(22.5rem / 7)',
                                        height: 'calc(18.75rem / 7)',
                                        fontSize: '1rem',
                                        margin: 0,
                                    },
                                    '& > button': {
                                        width: '100%',
                                        height: '100%',
                                        fontSize: '1rem',
                                        margin: 0,
                                        '&:disabled': {
                                            color: '#cccccc',
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        } }, year_style), month_style), { margin: '0' });
});
var StyledModalCalendar = styled(Box)(function (props) { return (__assign(__assign(__assign({ minWidth: '24rem', backgroundColor: props.theme.palette.white.main }, d_flex_center), dir_column), { '.timebox_wrap': {
        width: '100%',
        height: '358px',
        '.time_contents_box': {
            padding: '0 1rem 1rem',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem',
            '& > div': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                height: 'calc(358px - 4.5rem)',
                overflowY: 'auto',
                '& > button': {
                    minHeight: '2.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                width: '50%',
            },
        },
        '.timebox': {
            width: '100%',
            height: '4.5rem',
            padding: '0 1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem',
            button: {
                cursor: 'inherit',
                '&:hover': {
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(119, 119, 119, 0.5)',
                },
                width: '50%',
            },
        },
    } })); });
var StyledTitle = styled(Box)(function (props) { return (__assign(__assign({ width: '100%', height: '6rem', backgroundColor: '#FF453B !important', color: props.theme.palette.white.main, padding: '0 1rem', justifyContent: 'center' }, d_flex), dir_column)); });
var time_arr = ['오전', '오후'];
var hours_arr = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
];
var minutes_arr = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
function ModalCalendarStudent() {
    var modal_calendar_year = useContext(ModalContext).modal_calendar_year;
    var _a = useState(null), value = _a[0], setValue = _a[1];
    var _b = useState(setLocaleDateString(new Date(), 'kor')), localeDate = _b[0], setLocaleDate = _b[1];
    var _c = useState('year'), view = _c[0], setView = _c[1];
    var _d = useState(''), curYear = _d[0], setCurYear = _d[1];
    var _e = useState(false), modalTimeVisible = _e[0], setModalTimeVisible = _e[1];
    var _f = useState('00시'), hours = _f[0], setHours = _f[1];
    var _g = useState('00분'), minutes = _g[0], setMinutes = _g[1];
    var _h = useState(false), statActive = _h[0], setStatActive = _h[1];
    var _j = useState(null), dropdownEl = _j[0], setDropdownEl = _j[1];
    var _k = useState({ width: '', items: [] }), currentDropdownItems = _k[0], setCurrentDropdownItems = _k[1];
    useEffect(function () {
        var _a, _b;
        if (modal_calendar_year.data.visible) {
            if (modal_calendar_year.data.date[modal_calendar_year.data.type] ||
                modal_calendar_year.data.date[modal_calendar_year.data.type] === null) {
                setValue(modal_calendar_year.data.date[modal_calendar_year.data.type]);
                setLocaleDate(setLocaleDateString(modal_calendar_year.data.date[modal_calendar_year.data.type], 'kor'));
                if (modal_calendar_year.data.time && modal_calendar_year.data.date[modal_calendar_year.data.type] !== null) {
                    var cur_hours = Number((_a = modal_calendar_year.data.date[modal_calendar_year.data.type]) === null || _a === void 0 ? void 0 : _a.getHours());
                    var cur_minutes = Number((_b = modal_calendar_year.data.date[modal_calendar_year.data.type]) === null || _b === void 0 ? void 0 : _b.getMinutes());
                    setHours("".concat(cur_hours < 10 ? "0".concat(cur_hours) : cur_hours, "\uC2DC"));
                    var tmp_minutes = Math.ceil(cur_minutes / 5) * 5;
                    setMinutes("".concat(tmp_minutes < 10 ? "0".concat(tmp_minutes) : tmp_minutes, "\uBD84"));
                }
            }
            setView(modal_calendar_year.data.openTo);
        }
        else {
            setModalTimeVisible(false);
            setHours('00시');
            setMinutes('00분');
        }
    }, [modal_calendar_year.data.visible]);
    useEffect(function () {
        if (localeDate) {
            setCurYear(localeDate.slice(0, 4));
        }
    }, [localeDate]);
    var handleChange = function (date, type) {
        if (view === 'year') {
            setValue(date);
            setLocaleDate(setLocaleDateString(date, 'kor'));
        }
        if (view === 'month') {
            setValue(date);
            setLocaleDate(setLocaleDateString(date, 'kor'));
            if (modal_calendar_year.data.type === 'att_status' && date instanceof Date) {
                setLocaleDate(setLocaleDateString(date, 'kor'));
                modal_calendar_year.data.date[modal_calendar_year.data.type] = date;
                onCloseModal();
            }
        }
        if (view === 'day') {
            if (type == 'date') {
                setLocaleDate(setLocaleDateString(new Date("".concat(date)), 'kor'));
                if (modal_calendar_year.data.time) {
                    setModalTimeVisible(true);
                }
                else {
                    if (modal_calendar_year.data.key && modal_calendar_year.data.key == 'billing_format') {
                        var select_date = setLocaleDateString(date, '-');
                        var today = dayjs().format('YYYY-MM-DD');
                        if (select_date == today && modal_calendar_year.data.callback) {
                            modal_calendar_year.data.callback();
                            setValue(null);
                            setStatActive(false);
                        }
                        else {
                            modal_calendar_year.setModalCalendarDataDateType(new Date("".concat(date)), modal_calendar_year.data.type);
                            setValue(null);
                            setStatActive(false);
                            setView('year');
                            modal_calendar_year.closeModalCalendar();
                        }
                    }
                    else if (modal_calendar_year.data.type != 'accountStatisticsStart') {
                        modal_calendar_year.setModalCalendarDataDateType(new Date("".concat(date)), modal_calendar_year.data.type);
                        setView('year');
                        setValue(null);
                        setStatActive(false);
                        modal_calendar_year.closeModalCalendar();
                    }
                    else {
                        setStatActive(true);
                        setValue(date);
                        setLocaleDate(setLocaleDateString(date, 'kor'));
                    }
                }
            }
        }
    };
    var onSettingView = function (type) {
        if (type.includes('년')) {
            setView('year');
        }
        else if (type.includes('월')) {
            setView('month');
        }
        else {
            setView('day');
        }
        setModalTimeVisible(false);
    };
    var onCloseModal = function () {
        if (modalTimeVisible) {
            var cur_date_kor = "".concat(localeDate).replace(/[^0-9]/gi, '');
            var cur_date = "".concat(cur_date_kor.slice(0, 4), "-").concat(cur_date_kor.slice(4, 6), "-").concat(cur_date_kor.slice(6, 8));
            var cur_hours = hours.replace(/[^0-9]/gi, '');
            modal_calendar_year.setModalCalendarDataDateType(new Date("".concat(cur_date, " ").concat(cur_hours, ":").concat(minutes.replace(/[^0-9]/gi, ''), ":00")), modal_calendar_year.data.type);
            setView('year');
            setValue(null);
        }
        setView('year');
        setStatActive(false);
        modal_calendar_year.closeModalCalendar();
    };
    var selectTime = function (type, value) {
        if (type == 'hours') {
            setHours("".concat(value, "\uC2DC"));
        }
        else {
            setMinutes("".concat(value, "\uBD84"));
        }
    };
    return (_jsx(_Fragment, { children: _jsx(ModalDefault, __assign({ visible: modal_calendar_year.data.visible, onClose: onCloseModal }, { children: _jsxs(StyledModalCalendar, { children: [_jsxs(StyledTitle, { children: [_jsx(Box, __assign({ component: 'h4', sx: { color: 'gray_3.main' } }, { children: curYear }), void 0), _jsx(Box, __assign({ component: 'h2', sx: { cursor: 'pointer' } }, { children: localeDate.split(' ').map(function (item, idx) { return (_jsxs(Box, __assign({ component: 'span', onClick: function () { return onSettingView(item); } }, { children: [item, ' '] }), "date_item_".concat(idx))); }) }), void 0)] }, void 0), modalTimeVisible ? (_jsxs(Box, __assign({ className: 'timebox_wrap' }, { children: [_jsxs(Box, __assign({ className: 'timebox' }, { children: [_jsx(Button, __assign({ id: 'hours_menu', onClick: function () { return false; }, disableRipple: true, variant: 'outlined', color: 'black_4' }, { children: hours }), void 0), _jsx(Button, __assign({ id: 'minutes_menu', onClick: function () { return false; }, disableRipple: true, variant: 'outlined', color: 'black_4' }, { children: minutes }), void 0)] }), void 0), _jsxs(Box, __assign({ className: 'time_contents_box' }, { children: [_jsx(Box, __assign({ className: 'time_contents' }, { children: hours_arr.map(function (item, item_idx) {
                                            var active = hours == "".concat(item, "\uC2DC");
                                            return (_jsxs(Button, __assign({ variant: active ? 'contained' : 'text', color: active ? 'calendar_red' : 'black_4', onClick: function () { return selectTime('hours', item); } }, { children: [item, "\uC2DC"] }), "time_contents_hours_".concat(item_idx)));
                                        }) }), void 0), _jsx(Box, __assign({ className: 'time_contents' }, { children: minutes_arr.map(function (item, item_idx) {
                                            var active = minutes == "".concat(item, "\uBD84");
                                            return (_jsxs(Button, __assign({ variant: active ? 'contained' : 'text', color: active ? 'calendar_red' : 'black_4', onClick: function () { return selectTime('minutes', item); } }, { children: [item, "\uBD84"] }), "time_contents_minutes_".concat(item_idx)));
                                        }) }), void 0)] }), void 0)] }), void 0)) : (_jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDateFns }, { children: _jsx(CustomDatePicker, { openTo: 'year', views: ['year', 'month', 'day'], view: view, minDate: modal_calendar_year.data.min ? modal_calendar_year.data.min : new Date('1950-01-01'), maxDate: modal_calendar_year.data.max ? modal_calendar_year.data.max : new Date(), date: value, onYearChange: function () { return setView('month'); }, onMonthChange: function () { return setView('day'); }, onChange: function (date) { return handleChange(date, 'date'); }, className: statActive && modal_calendar_year.data.type == 'accountStatisticsStart'
                                ? 'account_stat'
                                : 'default_day', allowSameDateSelection: true }, void 0) }), void 0)), view == 'day' && statActive && modal_calendar_year.data.type == 'accountStatisticsStart' ? (_jsxs(Box, __assign({ sx: { display: 'flex', width: '100%', minHeight: '2.5rem', marginBottom: '-2px' } }, { children: [_jsx(Button, __assign({ color: 'green', variant: 'outlined', onClick: function () {
                                    modal_calendar_year.setModalCalendarDataDateType(new Date("".concat(setLocaleDateString(new Date(value), '-'), " 12:45:45")), 'accountStatisticsStart');
                                    modal_calendar_year.setModalCalendarDataDateType(new Date("".concat(value)), 'accountStatisticsEnd');
                                    onCloseModal();
                                }, sx: { pt: '1rem', pb: '1rem', width: 1, borderRadius: 0, height: '100%' } }, { children: "\uC77C\uC790\uBCC4 \uC870\uD68C" }), void 0), _jsx(Button, __assign({ color: 'green', variant: 'contained', onClick: function () {
                                    modal_calendar_year.setModalCalendarDataDateType(new Date("".concat(setLocaleDateString(new Date(value), '-'), " 09:00:00")), modal_calendar_year.data.type);
                                    onCloseModal();
                                }, sx: { pt: '1rem', pb: '1rem', width: 1, borderRadius: 0, height: '100%' } }, { children: "\uC2DC\uC791\uC77C \uC124\uC815" }), void 0)] }), void 0)) : (_jsx(Button, __assign({ color: modalTimeVisible ? 'green' : 'black', variant: modalTimeVisible ? 'contained' : 'text', onClick: onCloseModal, sx: { pt: '1rem', pb: '1rem', width: 1, borderRadius: modalTimeVisible ? '0' : '4px' } }, { children: modalTimeVisible ? '확인' : '취소' }), void 0))] }, void 0) }), void 0) }, void 0));
}
export default ModalCalendarStudent;
