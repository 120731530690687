var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, createContext, useRef } from 'react';
import ModalConfirm from '../components/modal/ModalConfirm';
import ModalAlert from '../components/modal/ModalAlert';
import ModalAlertOrigin from '../components/modal/ModalAlertOrigin';
import ModalCheck from '../components/modal/ModalCheck';
import ModalCalendar from '../components/modal/ModalCalendar';
import ModalCalendarStudent from '../components/modal/ModalCalendarStudent';
import { confirm_buttons, confirm_contents, alert_contents, check_contents, setTitle } from '../utils/modal_contents';
// ========================================================================================
// 초기값 설정
export var ModalContext = createContext({
    root_visible: false,
    setRootState: function (visible) {
        return;
    },
    modal_confirm: {
        data: {
            visible: false,
            title: '',
            left: '',
            right: '',
            button_type: '',
            confirm_callback: function () {
                return;
            },
            cancle_callback: function () {
                return;
            },
            payment_callback: function () {
                return;
            },
        },
        closeModalConfirm: function () {
            return;
        },
        openModalConfirm: function (button_type, type, confirm_callback, cancle_callback, target, payment_callback) {
            return;
        },
    },
    modal_alert: {
        data: {
            visible: false,
            title: '',
            timeout: undefined,
        },
        closeModalAlert: function () {
            return;
        },
        openModalAlert: function (type) {
            return;
        },
    },
    modal_alert_origin: {
        data: {
            visible: false,
            title: '',
            timeout: undefined,
        },
        closeModalAlertOrigin: function () {
            return;
        },
        openModalAlertOrigin: function (type) {
            return;
        },
    },
    modal_check: {
        data: {
            visible: false,
            title: '',
        },
        closeModalCheck: function () {
            return;
        },
        openModalCheck: function (type) {
            return;
        },
    },
    modal_calendar: {
        data: {
            visible: false,
            title: '',
            type: '',
            date: {
                start: new Date(),
                end: new Date(),
                reservation: new Date(),
            },
            max: new Date(),
            min: null,
        },
        closeModalCalendar: function () {
            return;
        },
        openModalCalendar: function (type, value, max, min, default_month) {
            return;
        },
        setModalCalendarDataDate: function (date_arr) {
            return;
        },
        setModalCalendarDataDateType: function (date, type) {
            return;
        },
        setModalCalendarDataDateClear: function () {
            return;
        },
    },
    modal_calendar_year: {
        data: {
            visible: false,
            title: '',
            type: '',
            openTo: 'year',
            date: {
                start: new Date(),
                end: new Date(),
            },
            max: new Date(),
            min: null,
            time: false,
            key: undefined,
            callback: undefined,
        },
        closeModalCalendar: function () {
            return;
        },
        openModalCalendar: function (type, value, max, min, default_month, openTo, time, key, callback) {
            return;
        },
        setModalCalendarDataDate: function (date_arr) {
            return;
        },
        setModalCalendarDataDateType: function (date, type) {
            return;
        },
        setModalCalendarDataDateClear: function () {
            return;
        },
    },
});
// ========================================================================================
// Provider 설정
function ModalProvider(props) {
    var children = props.children;
    // modalController 객체 안에 넣으면
    // 실시간 렌더 x
    // useState 사용 바람
    var _a = useState(false), rootVisible = _a[0], setRootVisible = _a[1];
    var _b = useState({
        visible: false,
        title: '',
        left: '',
        right: '',
        button_type: '',
        confirm_callback: function () {
            return;
        },
        cancle_callback: function () {
            return;
        },
        payment_callback: function () {
            return;
        },
    }), modalConfirmData = _b[0], setModalConfirmData = _b[1];
    var _c = useState({
        visible: false,
        title: '',
        timeout: undefined,
        use_close_button: false,
    }), modalAlertData = _c[0], setModalAlertData = _c[1];
    var _d = useState({
        visible: false,
        title: '',
        timeout: undefined,
        use_close_button: false,
    }), modalAlertOriginData = _d[0], setModalAlertOriginData = _d[1];
    var alert_timeout = useRef(undefined);
    alert_timeout.current = modalAlertData.timeout;
    var alert_origin_timeout = useRef(undefined);
    alert_origin_timeout.current = modalAlertOriginData.timeout;
    var _e = useState({
        visible: false,
        title: '',
    }), modalCheckData = _e[0], setModalCheckData = _e[1];
    var _f = useState({
        visible: false,
        title: '',
        type: '',
        date: {
            start: new Date(),
            end: new Date(),
            reservation: new Date(),
        },
        default: undefined,
        max: new Date(),
        min: null,
    }), modalCalendarData = _f[0], setModalCalendarData = _f[1];
    var _g = useState({
        visible: false,
        openTo: 'year',
        title: '',
        type: '',
        date: {
            start: new Date(),
            end: new Date(),
        },
        max: new Date(),
        min: null,
        time: false,
        key: '',
    }), modalCalendarYearData = _g[0], setModalCalendarYearData = _g[1];
    // 모달 컨트롤러
    var modalController = {
        root_visible: rootVisible,
        setRootState: function (visible) {
            setRootVisible(visible);
        },
        modal_confirm: {
            data: modalConfirmData,
            closeModalConfirm: function () {
                setModalConfirmData({
                    title: '',
                    visible: false,
                    left: '',
                    right: '',
                    button_type: '',
                    confirm_callback: function () {
                        return;
                    },
                    cancle_callback: function () {
                        return;
                    },
                    payment_callback: function () {
                        return;
                    },
                });
            },
            openModalConfirm: function (button_type, type, confirm_callback, cancle_callback, target, payment_callback) {
                var title_data = {
                    title: confirm_contents[type].title,
                    sub_title: confirm_contents[type].sub_title ? confirm_contents[type].sub_title : null,
                    target: target ? target : null,
                };
                setModalConfirmData({
                    title: setTitle(title_data),
                    visible: true,
                    left: confirm_buttons[button_type][0],
                    right: confirm_buttons[button_type][1],
                    button_type: button_type,
                    confirm_callback: confirm_callback,
                    cancle_callback: cancle_callback,
                    payment_callback: payment_callback,
                });
            },
        },
        modal_alert: {
            data: modalAlertData,
            closeModalAlert: function () {
                setModalAlertData(function (prev) { return (__assign(__assign({}, prev), { visible: false, timeout: undefined })); });
                if (alert_timeout.current != undefined) {
                    clearTimeout(alert_timeout.current);
                }
            },
            openModalAlert: function (type, target, callback, manualClose, target2, useCloseButton) {
                var title_data = {
                    title: alert_contents[type].title,
                    sub_title: alert_contents[type].sub_title ? alert_contents[type].sub_title : null,
                    target: target ? target : null,
                    target2: target2 ? target2 : undefined,
                };
                setModalAlertData(function (state) {
                    if (state.timeout) {
                        clearTimeout(state.timeout);
                    }
                    return {
                        visible: true,
                        title: setTitle(title_data),
                        use_close_button: useCloseButton === false ? false : true,
                        timeout: manualClose
                            ? undefined
                            : window.setTimeout(function () {
                                modalController.modal_alert.closeModalAlert();
                                if (callback)
                                    callback();
                            }, ['success_regi_account_history_wait', 'success_regi_account_history_with_cancel'].includes(type)
                                ? 4000
                                : 2000),
                    };
                });
            },
        },
        modal_alert_origin: {
            data: modalAlertOriginData,
            closeModalAlertOrigin: function () {
                setModalAlertOriginData(function (prev) { return (__assign(__assign({}, prev), { visible: false, timeout: undefined })); });
                if (alert_origin_timeout.current != undefined) {
                    clearTimeout(alert_origin_timeout.current);
                }
            },
            openModalAlertOrigin: function (type, target, callback, manualClose, target2, useCloseButton) {
                var title_data = {
                    title: alert_contents[type].title,
                    sub_title: alert_contents[type].sub_title ? alert_contents[type].sub_title : null,
                    target: target ? target : null,
                    target2: target2 ? target2 : undefined,
                };
                setModalAlertOriginData(function (state) {
                    if (state.timeout) {
                        clearTimeout(state.timeout);
                    }
                    return {
                        visible: true,
                        title: setTitle(title_data),
                        use_close_button: useCloseButton === false ? false : true,
                        timeout: manualClose
                            ? undefined
                            : window.setTimeout(function () {
                                modalController.modal_alert_origin.closeModalAlertOrigin();
                                if (callback)
                                    callback();
                            }, ['success_regi_account_history_wait', 'success_regi_account_history_with_cancel'].includes(type)
                                ? 4000
                                : 2000),
                    };
                });
            },
        },
        modal_check: {
            data: modalCheckData,
            closeModalCheck: function () {
                setModalCheckData({ visible: false, title: '' });
            },
            openModalCheck: function (type) {
                var title_data = {
                    title: check_contents[type].title,
                    sub_title: check_contents[type].sub_title ? check_contents[type].sub_title : null,
                    target: null,
                };
                setModalCheckData({ visible: true, title: setTitle(title_data) });
            },
        },
        modal_calendar: {
            data: modalCalendarData,
            closeModalCalendar: function () {
                setModalCalendarData(function (state) {
                    return __assign(__assign({}, state), { visible: false, default: undefined });
                });
            },
            openModalCalendar: function (type, value, max, min, default_month) {
                setModalCalendarData(function (state) {
                    var _a;
                    return __assign(__assign({}, state), { visible: true, type: type, date: __assign(__assign({}, state.date), (_a = {}, _a[type] = value ? value : null, _a)), default: default_month, max: max ? max : new Date('9999-12-12'), min: min ? min : null });
                });
            },
            setModalCalendarDataDate: function (date_arr) {
                // const data = setAgoDate(date_type, gap);
                setModalCalendarData(function (state) {
                    return __assign(__assign({}, state), { date: {
                            start: date_arr[0],
                            end: date_arr[1],
                        } });
                });
            },
            setModalCalendarDataDateType: function (date, type) {
                setModalCalendarData(function (state) {
                    var _a;
                    return __assign(__assign({}, state), { date: __assign(__assign({}, state.date), (_a = {}, _a[type] = date, _a)) });
                });
            },
            setModalCalendarDataDateClear: function () {
                setModalCalendarData(function (state) {
                    return __assign(__assign({}, state), { visible: false, date: __assign(__assign({}, state.date), { useDetailStart: null, useDetailEnd: null, allCheckStart: null, allCheckEnd: null, chargeDetailStart: null, chargeDetailEnd: null, checkStart: null, checkEnd: null, smsDetailStart: null, smsDetailEnd: null, smsReservationStart: null, smsReservationEnd: null }) });
                });
            },
        },
        modal_calendar_year: {
            data: modalCalendarYearData,
            closeModalCalendar: function () {
                setModalCalendarYearData(function (state) {
                    return __assign(__assign({}, state), { visible: false });
                });
            },
            openModalCalendar: function (type, value, max, min, default_month, openTo, time, key, callback) {
                var openStart = openTo ? openTo : 'year';
                setModalCalendarYearData(function (state) {
                    var _a;
                    return __assign(__assign({}, state), { visible: true, openTo: openStart, type: type, date: __assign(__assign({}, state.date), (_a = {}, _a[type] = value ? value : null, _a)), default: default_month, max: max ? max : new Date('9999-12-12'), min: min ? min : null, time: time === true ? true : false, key: key, callback: callback });
                });
            },
            setModalCalendarDataDate: function (date_arr) {
                // const data = setAgoDate(date_type, gap);
                setModalCalendarYearData(function (state) {
                    return __assign(__assign({}, state), { date: {
                            start: date_arr[0],
                            end: date_arr[1],
                        } });
                });
            },
            setModalCalendarDataDateType: function (date, type) {
                setModalCalendarYearData(function (state) {
                    var _a;
                    return __assign(__assign({}, state), { date: __assign(__assign({}, state.date), (_a = {}, _a[type] = date, _a)) });
                });
            },
            setModalCalendarDataDateClear: function () {
                setModalCalendarYearData(function (state) {
                    return __assign(__assign({}, state), { visible: false, date: __assign(__assign({}, state.date), { useDetailStart: null, useDetailEnd: null, allCheckStart: null, allCheckEnd: null, chargeDetailStart: null, chargeDetailEnd: null, checkStart: null, checkEnd: null, smsDetailStart: null, smsDetailEnd: null, smsReservationStart: null, smsReservationEnd: null, calculate_start: null, calculate_end: null, accountStatisticsStart: null, accountStatisticsEnd: null, counselRangeStart: null, counselRangeEnd: null, modify_startdate: null }) });
                });
            },
        },
    };
    return (_jsx(_Fragment, { children: _jsxs(ModalContext.Provider, __assign({ value: modalController }, { children: [children, _jsx(ModalConfirm, {}, void 0), _jsx(ModalAlert, {}, void 0), _jsx(ModalAlertOrigin, {}, void 0), _jsx(ModalCheck, {}, void 0), _jsx(ModalCalendar, {}, void 0), _jsx(ModalCalendarStudent, {}, void 0)] }), void 0) }, void 0));
}
export default ModalProvider;
