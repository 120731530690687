export var drop_contents_start = [
    { id: 1, label: 'OOO 학생이 OO시 OO분에 등원하였습니다.' },
    { id: 2, label: 'OOO 학생이 OO시 OO분에 학원에 도착하였습니다.^^' },
    { id: 3, label: 'OOO 학생이 OO시 OO분에 수업을 시작합니다 :)' },
    { id: 4, label: 'OOO 학생이 OO시 OO분에 안전하게 등원하였습니다♥!' },
    { id: 5, label: 'OOO 님이 OO시 OO분에 입실하였습니다.' },
    { id: 6, label: 'OOO 학생이 학원에 도착하였습니다.^^' },
    { id: 7, label: 'OOO 클래스 OOO 님이 OO시 OO분에 입실하였습니다.' },
];
export var drop_contents_end = [
    { id: 1, label: 'OOO 학생이 OO시 OO분에 하원하였습니다.' },
    { id: 2, label: 'OOO 학생이 OO시 OO분에 성실히 수업을 마쳤습니다.^^' },
    { id: 3, label: 'OOO 학생이 OO시 OO분에 수업이 종료되었습니다 :)' },
    { id: 4, label: 'OOO 학생이 OO시 OO분에 안전하게 하원하였습니다♥!' },
    { id: 5, label: 'OOO 님이 OO시 OO분에 퇴실하였습니다.' },
    { id: 6, label: 'OOO 학생이 성실히 수업을 마쳤습니다.^^' },
    { id: 7, label: 'OOO 클래스 OOO 님이 OO시 OO분에 퇴실하였습니다.' },
];
export var drop_contents_title = [
    { id: 1, label: '■ {학원명} 등하원 안내 ■' },
    { id: 2, label: '■ {학원명} 출결 안내 ■' },
    { id: 3, label: '■ {학원명} 학부모 안심문자 ■' },
    { id: 4, label: '♬ {학원명} 출결 알림서비스 ♬' },
    { id: 5, label: '{학원명} 입니다.' },
];
export var drop_setting_attendance_point = [
    { id: 0, label: 'AM 00시' },
    { id: 1, label: 'AM 01시' },
    { id: 2, label: 'AM 02시' },
    { id: 3, label: 'AM 03시' },
    { id: 4, label: 'AM 04시' },
    { id: 5, label: 'AM 05시' },
    { id: 6, label: 'AM 06시' },
    { id: 7, label: 'AM 07시' },
    { id: 8, label: 'AM 08시' },
    { id: 9, label: 'AM 09시' },
    { id: 10, label: 'AM 10시' },
];
export var drop_setting_end_possible_time = [
    { id: 0, label: '0분 후' },
    { id: 1, label: '5분 후' },
    { id: 2, label: '10분 후' },
    { id: 3, label: '15분 후' },
    { id: 4, label: '20분 후' },
    { id: 5, label: '25분 후' },
    { id: 6, label: '30분 후' },
    { id: 7, label: '35분 후' },
    { id: 8, label: '40분 후' },
    { id: 9, label: '45분 후' },
    { id: 10, label: '50분 후' },
    { id: 11, label: '55분 후' },
    { id: 12, label: '60분 후' },
    { id: 13, label: '65분 후' },
    { id: 14, label: '70분 후' },
    { id: 15, label: '75분 후' },
    { id: 16, label: '80분 후' },
    { id: 17, label: '85분 후' },
    { id: 18, label: '90분 후' },
    { id: 19, label: '95분 후' },
    { id: 20, label: '100분 후' },
    { id: 21, label: '105분 후' },
    { id: 22, label: '110분 후' },
    { id: 23, label: '115분 후' },
    { id: 24, label: '120분 후' },
];
export var drop_setting_attendance_count = [
    { id: 0, label: '일 1회' },
    { id: 1, label: '제한 없음' },
];
export var drop_setting_format = [
    { id: 0, label: '24시간 제' },
    { id: 1, label: '12시간 제' },
];
export var drop_due_date = [
    { id: 1, label: '1' },
    { id: 2, label: '2' },
];
export var drop_due_date2 = [
    { id: 1, label: '1' },
    { id: 2, label: '2' },
    { id: 3, label: '3' },
];
export var drop_account_sms = [
    { id: 7, label: 'AM 07:00' },
    { id: 8, label: 'AM 08:00' },
    { id: 9, label: 'AM 09:00' },
    { id: 10, label: 'AM 10:00' },
    { id: 11, label: 'AM 11:00' },
    { id: 12, label: 'PM 12:00' },
    { id: 13, label: 'PM 13:00' },
    { id: 14, label: 'PM 14:00' },
    { id: 15, label: 'PM 15:00' },
    { id: 16, label: 'PM 16:00' },
    { id: 17, label: 'PM 17:00' },
    { id: 18, label: 'PM 18:00' },
    { id: 19, label: 'PM 19:00' },
    { id: 20, label: 'PM 20:00' },
    { id: 21, label: 'PM 21:00' },
    { id: 22, label: 'PM 22:00' },
    { id: 23, label: 'PM 23:00' },
];
export var drop_attendance_contain = [
    { id: 0, label: '미포함' },
    { id: 1, label: '포함' },
];
