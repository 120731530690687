var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { OutlinedInput, FormHelperText, styled, Box, Typography, } from '@mui/material';
import React from 'react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import InputTitle from '../common/InputTitle';
import { useState, useEffect } from 'react';
import { IoCloseCircleSharp } from 'react-icons/io5';
// Input Style 작업 중
var StyledInput = styled(OutlinedInput)(function (props) { return ({
    fontSize: '1.1rem',
    width: '100%',
    height: '2.5rem',
    marginBottom: '0.5rem',
    outline: 'none',
    '&.Mui-disabled.MuiInputBase-colorGray_1': {
        backgroundColor: '#f5f5f5',
        '& input': {
            WebkitTextFillColor: 'var(--gray_1)',
        },
        '& fieldset': {
            borderColor: 'var(--gray-2) !important',
        },
    },
    '&.Mui-error ': {
        '& fieldset': {
            borderColor: "".concat(props.theme.palette.red.main, " !important"),
        },
    },
    '&.Mui-focused': {
        fieldset: {
            borderWidth: '1px !important',
        },
    },
    '& input': {
        cursor: "".concat(props.type === 'select' ? 'pointer' : 'auto'),
        padding: '0.5rem 1rem',
        '&::placeholder': {
            color: '#c7c7c7 !important',
            transition: 'none',
            fontWeight: '300',
            opacity: '1',
        },
        '&:disabled': {
            backgroundColor: '#f5f5f5 !important',
            cursor: 'default',
        },
    },
    '&.dot_password': {
        input: {
            WebkitTextSecurity: 'disc',
        },
    },
    '&:hover': {
        'input, fieldset': {
            borderColor: 'var(--gray-2)',
        },
    },
    '& fieldset': {
        borderColor: 'var(--gray-2) !important',
        legend: {
            outline: 'none',
        },
    },
    '.password_icon': {
        position: 'absolute',
        right: '0.9rem',
        width: '1.4rem',
        height: '1.4rem',
        cursor: 'pointer',
        color: '#bdbbbc',
    },
}); });
var CustomFormHelperText = styled(FormHelperText)(function (_a) {
    var toLongText = _a.toLongText, id = _a.id;
    var top_bottom_position = id === 'ModalAccountLink'
        ? { bottom: '-1.5rem' }
        : id === 'direct_input'
            ? {}
            : toLongText
                ? { top: 'calc(15.25rem / 3)' }
                : { top: 'calc(2.25rem / 3)' };
    return __assign({ position: id === 'direct_input' ? 'relative' : 'absolute', right: '0', color: 'var(--red)', fontSize: '0.85rem' }, top_bottom_position);
});
// select type의 오른쪽 아래 삼각형
var edit_triangle = {
    content: '',
    position: 'absolute',
    right: 0,
    bottom: '0.5rem',
    width: 0,
    height: 0,
    borderLeft: '7px solid transparent',
    borderRight: '7px solid #c2c2c2',
    borderTop: '7px solid transparent',
    borderBottom: '7px solid #c2c2c2',
    borderBottomRightRadius: '4px',
    marginBottom: '1px',
    cursor: 'pointer',
    zIndex: 1,
};
var CustomXIcon = styled(IoCloseCircleSharp)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '2.5rem',
        height: '2.5rem',
        color: '#c7c7c7',
        cursor: 'pointer',
        padding: '0.5rem',
        marginRight: '-0.5rem',
        position: 'absolute',
        top: '2.7rem',
        right: '1rem',
    });
});
var CloseEye = styled(IoMdEyeOff)(function (_a) {
    var theme = _a.theme;
    return ({
        transform: 'scaleX(-1)',
    });
});
var OpenEye = styled(IoMdEye)(function (_a) {
    var theme = _a.theme;
    return ({});
});
var InputNormal = React.forwardRef(function (props, ref) {
    var title = props.title;
    var sx = props.sx;
    var tooltip = props.tooltip;
    var required = props.required;
    var input_type = props.type ? props.type : 'text';
    var placeholder = props.placeholder;
    var id = props.id;
    var disable = props.disable;
    var value = props.value;
    var visible_button = props.visibleButton;
    var classification = props.classification;
    var read_only = props.readOnly;
    var error = props.error;
    var error_text = props.errorText;
    var auto_focus = props.autoFocus;
    var onChange = props.onChange;
    var onClickButtonName = props.onClickButtonName;
    var onClickButtonTime = props.onClickButtonTime;
    var onFocus = props.onFocus;
    var onKeyDown = props.onKeyDown;
    var onKeyPress = props.onKeyPress;
    var onMouseDown = props.onMounseDown;
    var onBlur = props.onBlur;
    var onClickDelete = props.onClickDelete;
    var title_bold = props.titleBold;
    var title_sx = props.titleSx;
    var use_password_icon = props.usePasswordIcon;
    var use_delete_icon = props.useDeleteIcon;
    var input_sx = props.inputSx;
    var icon = props.icon;
    var _a = useState(''), inputType = _a[0], setInputType = _a[1];
    var _b = useState(false), passwordVisible = _b[0], setPasswordVisible = _b[1];
    var _c = useState(null), endIcon = _c[0], setEndIcon = _c[1];
    var CloseEyeButton = function () {
        return (_jsx(CloseEye, { className: 'password_icon', onClick: function () {
                setPasswordVisible(true);
            } }, void 0));
    };
    var OpenEyeButton = function () {
        return (_jsx(OpenEye, { className: 'password_icon', onClick: function () {
                setPasswordVisible(false);
            } }, void 0));
    };
    useEffect(function () {
        if (input_type == 'password') {
            if (use_password_icon) {
                setEndIcon(_jsx(CloseEyeButton, {}, void 0));
            }
            setInputType('text');
        }
        else {
            setInputType(input_type);
        }
    }, [input_type]);
    useEffect(function () {
        if (!passwordVisible) {
            setEndIcon(_jsx(CloseEyeButton, {}, void 0));
        }
        else {
            setEndIcon(_jsx(OpenEyeButton, {}, void 0));
        }
    }, [passwordVisible]);
    var handleInput = function (event) {
        if (onChange) {
            onChange(event, classification);
        }
    };
    var removeData = function () {
        if (onClickDelete) {
            onClickDelete('ubirth');
        }
    };
    return (_jsxs(_Fragment, { children: [title ? (_jsx(InputTitle, { title: title, tooltip: tooltip, required: required, visibleButton: visible_button ? visible_button : false, onClickButtonName: onClickButtonName ? onClickButtonName : undefined, onClickButtonTime: onClickButtonTime ? onClickButtonTime : undefined, classification: classification, sx: __assign({ mb: '0' }, title_sx), bold: title_bold, icon: icon }, void 0)) : null, _jsxs(Box, __assign({ sx: sx ? sx : { width: 1, position: 'relative' } }, { children: [_jsx(StyledInput, { id: id !== null && id !== void 0 ? id : 'input_normal', name: id, type: inputType, placeholder: placeholder, color: 'gray_1', readOnly: inputType === 'select' || read_only ? true : false, disabled: disable ? true : false, value: value, onChange: handleInput, onClick: inputType === 'select' && !disable ? props.onClick : undefined, error: error, onFocus: onFocus, onBlur: onBlur, onKeyDown: onKeyDown, onMouseDown: onMouseDown, autoFocus: auto_focus, onKeyPress: onKeyPress, ref: ref, sx: input_sx, endAdornment: input_type == 'password' ? endIcon : null, autoComplete: inputType == 'password' ? 'one-time-code' : 'off', className: input_type == 'password' && !passwordVisible ? 'dot_password' : '' }, void 0), inputType === 'select' && id === 'payment_due_date' && value ? (_jsxs(Box, __assign({ onClick: props.onClick, sx: {
                            position: 'absolute',
                            top: 'calc(50% - 0.25rem + 1px)',
                            left: '1rem',
                            color: '#d4d4d4',
                            cursor: 'pointer',
                            transform: 'translateY(-50%)',
                            backgroundColor: '#fff',
                        } }, { children: [_jsx(Typography, __assign({ component: 'span', sx: { color: 'rgba(0, 0, 0, 0.87)' } }, { children: value }), void 0), ' ', "(\uCCAD\uAD6C\uC77C \uD3EC\uD568)"] }), void 0)) : null, inputType === 'select' && id === 'payment_period' && value ? (_jsxs(Box, __assign({ onClick: props.onClick, sx: {
                            height: 'calc(100% - 1rem)',
                            position: 'absolute',
                            top: '50%',
                            left: '0',
                            marginLeft: '1rem',
                            textAlign: 'center',
                            width: 'calc(100% - 2rem)',
                            color: '#d4d4d4',
                            cursor: 'pointer',
                            transform: 'translateY(-50%)',
                            backgroundColor: '#fff',
                            fontSize: '1.1rem',
                        } }, { children: [_jsx(Typography, __assign({ component: 'span', sx: { color: 'rgba(0, 0, 0, 0.87)', fontSize: '1.1rem' } }, { children: value }), void 0), ' ', "(\uCCAD\uAD6C\uC77C \uD3EC\uD568)"] }), void 0)) : null, inputType === 'select' ? _jsx("span", { className: 'input_triangle', style: edit_triangle }, void 0) : null, use_delete_icon && value !== '' ? _jsx(CustomXIcon, { onClick: removeData, className: 'x_icon' }, void 0) : null, error && error_text ? (_jsx(CustomFormHelperText, __assign({ id: id, toLongText: id === 'memno' && error_text.length > 34 ? true : false }, { children: error_text }), void 0)) : null] }), void 0)] }, void 0));
});
InputNormal.displayName = 'InputNormal';
export default InputNormal;
