export function setTitle(payload) {
    var title = payload.title;
    var sub_title = payload.sub_title;
    if (!payload.sub_title) {
        var tmp_title = title;
        if (!payload.target) {
            return tmp_title;
        }
        else {
            tmp_title = tmp_title.replace('target', "".concat(payload.target));
            if (payload.target2) {
                tmp_title = tmp_title.replace('target2', "".concat(payload.target2));
            }
            return tmp_title;
        }
    }
    else {
        var tmp_title = title;
        var tmp_sub_title = sub_title;
        if (payload.target) {
            tmp_sub_title = tmp_sub_title === null || tmp_sub_title === void 0 ? void 0 : tmp_sub_title.replace('target', payload.target);
            tmp_title = title === null || title === void 0 ? void 0 : title.replace('target', payload.target);
        }
        return "<span>".concat(tmp_title, "</span><span class=\"sub_title\">").concat(tmp_sub_title, "</span>");
    }
}
export var confirm_buttons = {
    normal: ['확인', '취소'],
    normal_reverse: ['취소', '확인'],
    normal_reverse_color: ['확인', '취소'],
    edit: ['수정', '취소'],
    registration: ['등록', '취소'],
    edit_reverse: ['취소', '수정'],
    registration_reverse: ['취소', '등록'],
    power: ['종료', '취소'],
    delete: ['취소', '삭제'],
    sum: ['합산 발송', '따로 발송'],
    account_refund: ['아니요', '결제 취소'],
    resend: ['재발행', '아니요'],
    resend_change: ['수정 발행', '아니요'],
    clear: ['취소', '해제'],
};
export var confirm_contents = {
    set_presendday: {
        title: '당월 청구서는 사전 발송 불가합니다.\r\ntarget',
    },
    empty: {
        title: 'target',
    },
    add_contents: { title: 'target 등록하시겠습니까?' },
    update_contents: { title: 'target 수정하시겠습니까?' },
    update_student: {
        title: '학생 정보를 수정하시겠습니까?',
        sub_title: '<span class="text-gray-1">(수납관리 대상자의 경우에도 함께 적용)</span>',
    },
    delete_contents: {
        title: '선택 항목을 정말 삭제하시겠습니까?',
        sub_title: '<span class="text-gray-1">(삭제된 target 복구되지 않습니다.)</span>',
    },
    upadte_student_class: {
        title: '선택 학생을 클래스 추가하시겠습니까?',
        sub_title: '<span class="text-gray-1">(중복 수강인 경우에 활용 <span class="text-red"> ex) 영어, 수학 동시 수강</span>)</span>',
    },
    move_student_class: {
        title: '선택 학생을 클래스 이동하시겠습니까?',
    },
    delete_contents_normal: { title: 'target 삭제하시겠습니까?' },
    print_contents: { title: 'target 출력하시겠습니까?' },
    excel_contents: { title: 'target 저장하시겠습니까?' },
    update_student_classification: { title: '선택 학생을 target 하시겠습니까?' },
    update_unregistered_student_classification: {
        title: '선택 학생을 target 하시겠습니까?',
        sub_title: '<span class="text-gray-1">* 재원처리 후 다시 미등록생이 될 수 없습니다.</span>',
    },
    registering: { title: '작성 중인 내용이 있습니다.\r\ntarget' },
    absence_student: {
        title: '선택 학생을 휴원처리 하시겠습니까?',
        sub_title: '<span class="text-gray-1">(휴원 시 수납관리 대상에서도 제외)</span>',
    },
    normal_student: { title: '선택 학생을 재원처리 하시겠습니까?' },
    dropout_student: {
        title: '선택 학생을 퇴원처리 하시겠습니까?',
        sub_title: '<span class="text-gray-1">(퇴원 시 수납관리 대상에서도 제외)</span>',
    },
    delete_student: {
        title: '선택 항목을 정말 삭제하시겠습니까?',
        sub_title: '<span class="text-gray-1">(삭제된 학생은 복구되지 않습니다.)</span>',
    },
    delete_account: {
        title: '선택된 청구서를 정말 취소하시겠습니까?',
        sub_title: '<span class="text-gray-1">(취소된 청구서는 복구되지 않습니다.)</span>',
    },
    hard_delete_account: {
        title: '선택된 청구내역을 정말 삭제하시겠습니까?',
        sub_title: '<span class="text-gray-1">(삭제된 청구내역은 복구되지 않습니다.)</span>',
    },
    hard_delete_account_history: {
        title: '선택된 수납내역을 정말 삭제하시겠습니까?',
        sub_title: '<span class="text-gray-1">(삭제된 수납내역은 복구되지 않습니다.)</span>',
    },
    change_period_format: {
        title: '입력하신 내용이 초기화됩니다.\r\n기간을 변경하시겠습니까?',
    },
    reset_history_data: {
        title: '입력하신 내용이 적용되지 않습니다.\r\n단건 입력을 종료하시겠습니까?',
    },
    reset_form_data: {
        title: '입력하신 내용이 초기화 됩니다.\r\n변경하시겠습니까?',
    },
    cancel_form_data: {
        title: '입력하신 내용이 적용되지 않습니다.\r\ntarget 종료하시겠습니까?',
    },
    registration_students: { title: '학생을 일괄등록 하시겠습니까?' },
    download_temperature: { title: '체온 기록을 다운로드 하시겠습니까?' },
    download_contents: { title: 'target 다운로드 하시겠습니까?' },
    quit_checkbus: { title: '출결버스 관리자를 종료하시겠습니까?' },
    update_attendance_sms: { title: '출결 메시지 발송target' },
    scrutinize_student_attendance: {
        title: 'target 학생 출결을 상세조회 하시겠습니까?',
    },
    set_message_setting: { title: '메시지 설정을 수정하시겠습니까?' },
    set_message_type: { title: 'target 발송 설정하시겠습니까?' },
    set_default_setting: { title: '기본 설정을 수정하시겠습니까?' },
    attendance_excel_save: { title: '출결내역을 엑셀로 저장하시겠습니까?' },
    attendance_print: { title: 'target 학생 출결분석을 출력하시겠습니까?' },
    select_contents_date: { title: 'target 조회하시겠습니까?' },
    send_sms: { title: 'SMS를 발송하시겠습니까?' },
    reservation_sms: { title: 'SMS를 예약하시겠습니까?' },
    fix_sms_template: { title: '해당 target 상단 고정을 하시겠습니까?' },
    unfix_sms_template: { title: '해당 target 고정 해제하시겠습니까?' },
    select_sms_template: { title: '해당 문자를 선택하시겠습니까?' },
    delete_sms_template: { title: '해당 문자를 삭제하시겠습니까?' },
    resend_sms_template: { title: '해당 target 재발송하시겠습니까?' },
    go_cash_page: { title: '출결캐쉬 충전을 하시겠습니까?' },
    change_reservation_sms: { title: '예약 정보를 변경하시겠습니까?' },
    cancel_reservation_sms: { title: '메시지 예약을 취소하시겠습니까?' },
    inquiry_question: { title: '1대1 문의를 접수하시겠습니까?' },
    withdraw_service: { title: '정말 서비스 탈퇴하시겠습니까?' },
    move_magazine: { title: '출결버스 매거진을 전체보기 하시겠습니까?' },
    move_main: { title: '출결버스 메인으로 돌아가시겠습니까?' },
    tmp_student_list: {
        title: '설정된 출석 요일이 아닙니다.\r\n학생 목록을 임시로 불러오시겠습니까?',
    },
    registration_receipt: {
        title: '지출증빙 정보를 등록하시겠습니까?',
        sub_title: '<span class="text-gray-1">(등록된 정보는 변경 불가합니다.)</span>',
    },
    reset_image: {
        title: '기본 target 설정하시겠습니까?',
    },
    confirm_write_time: {
        title: '수동으로 시간을 입력하시겠습니까?',
    },
    confrim_update_account_costday: {
        title: 'target 항목으로 수정하시겠습니까?',
    },
    confirm_blur_time: {
        title: '입력된 시각은 올바른 형식의 시각이 아닙니다.\r\n입력을 중지하시겠습니까?',
    },
    confirm_blur_temperature: {
        title: '입력된 체온은 올바른 형식의 체온이 아닙니다.\r\n입력을 중지하시겠습니까?',
    },
    move_page: { title: 'target 이동하시겠습니까?' },
    create_temp_all: { title: '체온을 일괄 기록하시겠습니까?' },
    update_auto_sms: { title: '수납 메시지 발송을 target하시겠습니까?' },
    stop_auto_sms: {
        title: '수납 메시지 발송을 중단하시겠습니까?',
        sub_title: '<span class="text-red">(청구대상의 자동 발송기능도 제한됩니다.)</span>',
    },
    update_account_sms: { title: 'target 발송 설정하시겠습니까?' },
    update_account_message: { title: '수납 target 내용을 수정하시겠습니까?' },
    update_account_alim_no_link: {
        title: '비대면 결제를 위해 청구서는 반드시 필요합니다.\r\n청구서 항목을 정말 해제하시겠습니까?',
    },
    update_account_sms_no_link: {
        title: '비대면 결제를 위해 결제링크는 반드시 필요합니다.\r\n결제링크 항목 없이 수정하시겠습니까?',
    },
    cancel_change_product: { title: '상품변경 신청을 철회하시겠습니까?' },
    delete_account_target: {
        title: '청구대상을 삭제하시겠습니까?',
        sub_title: '<span class="text-red">(청구내역은 삭제되며, 통계 금액 데이터만 보관됩니다.)</span>',
    },
    cancel_payment_complete: {
        title: '정말 결제를 취소하시겠습니까?',
        sub_title: '<span class="text-red">(취소된 청구서는 복구되지 않습니다.)</span>',
    },
    cancel_bill: { title: '발행된 청구서를 취소하시겠습니까?' },
    resend_bill: { title: '청구서를 재발행하시겠습니까?' },
    sum_money: {
        title: '현재 결제대기 건이 존재합니다.\r\n대기건을 취소하고 금액을 합산하시겠습니까?',
    },
    change_add_account_target: {
        title: '추가 대상 변경 시 입력하신 내용 및 요약 내용이 삭제 됩니다.\r\n추가 대상을 변경하시겠습니까?',
    },
    search_account_target: {
        title: '검색 대상 변경 시 입력하신 내용 및 요약 내용이 삭제 됩니다.\r\n검색 대상을 변경하시겠습니까?',
    },
    send_preview_bill: {
        title: 'target에 청구서를\r\n발송하시겠습니까?',
    },
    send_preview_bills: {
        title: '선택된 청구일자에 청구서를\r\n발송하시겠습니까?',
    },
    delete_summary_bill: {
        title: '해당 청구서를 삭제하시겠습니까?',
    },
    have_bill_left: {
        title: '발송하지 않은 청구서가 있습니다.\r\ntarget',
    },
    no_wait_payment: {
        title: '요약 청구서를 생성하시겠습니까?',
    },
    send_attendance: {
        title: '해당 학생의 출결SMS가 등록되지 않았습니다.\r\n메시지 발송 없이 처리하시겠습니까?',
    },
    set_direct_input: { title: 'target 수동으로 입력하시겠습니까?' },
    update_direct_input: { title: '입력하신 target 수정하시겠습니까?' },
    update_account_target: {
        title: '현재 수정사항을 저장하시겠습니까?\r\n이후 청구내역에 적용 됩니다.',
    },
    change_account_count: { title: '잔여 횟수를 target회로 바꾸시겠습니까?' },
    change_account_count_zero: {
        title: '잔여 횟수를 모두 차감하시겠습니까?\r\n다음 청구서는 target에 자동 발행됩니다.',
    },
    delete_presend_account: {
        title: '출결 횟수, 사전 발송을 변경할 경우\r\n기존 청구체계는 삭제되고, 새롭게 설정됩니다.',
    },
    payment_more: { title: '입금기한을 3일 연장하시겠습니까?' },
    send_over_alim: {
        title: '만료된 청구서를 재발행하시겠습니까?',
        sub_title: '<span class="text-red">(새로운 청구서가 즉시 발송됩니다.)</span>',
    },
    single_send_over_alim: {
        title: '만료된 청구서를 즉시 재발행하시겠습니까?',
    },
    single_send_over_alim_change: {
        title: '만료된 청구서를 수정 발행하시겠습니까?',
    },
    confirm_update_billing_format: {
        title: '청구 방식을 다시 선택하시겠습니까?',
        sub_title: '<span class="text-gray-1">(청구내역은 삭제되며, 되돌릴 수 없습니다.)</span>',
    },
    confirm_update_account_count: {
        title: '출결 횟수를 정말 수정하시겠습니까?',
        sub_title: '<span class="text-gray-1">(현재 진행중인 청구서의 전체횟수/진행횟수 모두 초기화)</span>',
    },
    confirm_uncheck_account_bill: {
        title: '세무회계 신고를 위한 필수 항목입니다.\r\n해당 항목을 정말 해제하시겠습니까?',
    },
    add_account_receipt: { title: '현금영수증을 발급하시겠습니까?' },
    cancel_account_receipt: { title: '현금영수증을 취소하시겠습니까?' },
    change_alim_costday: { title: '알림톡 내용을 변경하시겠습니까?' },
    change_display: { title: '화면 비율을 변경하시겠습니까?' },
    no_send_attendnace_record: {
        title: '좌측 상단 출결 메시지 발송이 해제 되어 있습니다.target',
    },
    empty_attendance_manager: {
        title: 'target',
    },
    change_all_presendday: {
        title: '당월 사전 발송이 불가한 학생이 있습니다.\r\n당월만 청구일에 발송, 익월부터 사전 발송일에 발송됩니다.',
    },
    change_all_chargedate: {
        title: '당월 발송이 불가한 학생이 있습니다.\r\n당월만 기존 청구일에 발송, 익월부터 변경된 발송일에 발송됩니다.',
    },
};
export var alert_contents = {
    select_time_hour: { title: '올바른 시간을 입력해주세요.' },
    select_time_minute: { title: '5분 단위로만 입력 가능합니다.' },
    empty: { title: 'target' },
    login: { title: 'target 선생님, 환영합니다.' },
    login_wrong: { title: '아이디 or 비밀번호가 일치하지 않습니다.' },
    login_wrong_cnt: {
        title: '아이디 or 비밀번호가 일치하지 않습니다.',
        sub_title: 'target',
    },
    login_empty: { title: '모든 정보를 입력해주세요.' },
    login_auto_expires_error: {
        title: '로그인 유지 기간이 지났습니다.\r\n다시 로그인해주세요.',
    },
    over_wrong_number: {
        title: '보안문제로 인해 로그인 시도 5회 만료.\r\n하단 계정 찾기를 통해 재설정하세요.',
    },
    success_add: { title: 'target 신규 등록되었습니다.' },
    success_add2: { title: 'target 추가되었습니다.' },
    success_add3: { title: 'target 등록되었습니다.' },
    success_add4: { title: 'target 생성되었습니다.' },
    success_update: { title: 'target 수정되었습니다.' },
    success_cancel: { title: 'target 취소되었습니다.' },
    success_move: { title: 'target 이동되었습니다.' },
    success_classification_update: { title: '학생이 target 되었습니다.' },
    success_delete: { title: 'target 삭제되었습니다.' },
    success_batch_message: { title: '일괄 target처리가 되었습니다.' },
    success_regi_account_history: { title: '단건 입력이 완료되었습니다.' },
    success_regi_account_history_wait: {
        title: '단건 입력이 완료되었습니다.\r\n(청구대기건 내역은 자동 취소)',
    },
    success_regi_account_history_with_cancel: {
        title: '단건 입력이 완료되었습니다.\r\n(기존 청구건은 메시지 발송없이 취소)',
    },
    success_update_account_history: { title: '단건 수정이 완료되었습니다.' },
    success_delete_account_history: { title: '단건 삭제가 완료되었습니다.' },
    search_error: { title: '검색내용에 맞는 학생이 존재하지 않습니다.' },
    time_format_error: { title: 'HH:mm 형식에 맞게 입력해주세요.' },
    no_member: { title: '삭제된 청구대상에게는 발송이 불가합니다.' },
    time_order_error: {
        title: '등원은 하원보다 이른 시각을 입력할 수 없습니다.',
    },
    starttime_order_error: {
        title: '등원은 하원보다 늦은 시간을 입력할 수 없습니다.',
    },
    endtime_order_error: {
        title: '하원은 등원보다 이른 시간을 입력할 수 없습니다.',
    },
    input_attendance_time: { title: '출석 시각이 입력되었습니다.' },
    no_required_edit_page: { title: 'target 먼저 등록해주세요.' },
    record_temperature: { title: '체온이 기록되었습니다.' },
    modify_temperature: { title: 'target 학생의 체온기록이 수정되었습니다.' },
    wrong_temperature: { title: '올바른 체온을 입력해주세요.' },
    send_temperature_message: { title: '체온 기록 메시지가 발송되었습니다.' },
    send_temperature_message_free: {
        title: '체온 기록 메시지가 발송되었습니다.\r\n남은 문자 수 target건 남았습니다.',
    },
    success_setting: { title: 'target 설정되었습니다.' },
    message_setting_modify: { title: '메시지 설정이 수정되었습니다.' },
    default_setting_modify: { title: '기본 설정이 수정되었습니다.' },
    send_sms: { title: 'target 발송되었습니다.' },
    send_sms_free: {
        title: '문자가 발송되었습니다.\r\n남은 문자 수 target건 남았습니다.',
    },
    send_alim_free: {
        title: '알림톡이 발송되었습니다.\r\n남은 문자 수 target건 남았습니다.',
    },
    send_counsel_free: {
        title: '상담일지가 알림톡으로 발송되었습니다.\r\n남은 문자 수 target건 남았습니다.',
    },
    attendance_only_record: { title: 'target 기록이 완료되었습니다.' },
    reservation_sms: {
        title: 'SMS가 예약되었습니다.\r\n(메시지 예약내역에서 수정 및 취소 가능)',
    },
    send_talk: { title: 'target 되었습니다.' },
    enter_wrong_number: { title: '올바른 번호를 입력해주세요.' },
    success_fix_sms_template: { title: '상단 고정이 완료되었습니다.' },
    success_unfix_sms_template: { title: '상단 고정이 해제되었습니다.' },
    apply_sms_template: { title: '해당 문자가 선택되었습니다.' },
    success_delete_sms_template: { title: '자주 쓰는 문자가 삭제되었습니다.' },
    success_update_attendance_sms: { title: '출결 메시지target' },
    success_resend: { title: 'target 재발송되었습니다.' },
    success_change_reservation_sms: { title: '예약 정보가 변경되었습니다.' },
    success_cancel_reservation_sms: { title: '메시지 예약이 취소되었습니다.' },
    reservation_error: { title: '현재 시간에서 최소 10분 뒤로 설정해주세요.' },
    reservation_error_2: { title: '현재 시간에서 최소 15분 뒤로 설정해주세요.' },
    reservation_error_3: {
        title: '이전 날짜로는 발송 및 예약을 하실 수 없습니다.',
    },
    success_inquiry_question: { title: '1대1 문의가 접수되었습니다.' },
    success_withdraw_service: { title: '서비스 탈퇴가 접수되었습니다.' },
    additional_payment_withdraw_service: {
        title: '서비스 탈퇴가 접수되었으며,\r\n잔여 이용금액을 납부부탁드립니다.',
    },
    next_withdraw: {
        title: '탈퇴 신청이 완료되었습니다.\r\n서비스 사용기간 만료시 탈퇴됩니다.',
    },
    direct_withdraw: {
        title: '탈퇴 신청이 완료되었습니다.\r\n출결버스를 이용해주셔서 감사드립니다.',
    },
    success_change_product_inquiry: { title: '상품변경 신청이 완료되었습니다.' },
    failed_reservation: {
        title: '발송예약 시간 10분 이내로는 수정, 취소가 불가합니다.',
    },
    cash_application_completed: { title: '전송된 전용계좌로 납부 부탁드립니다.' },
    duplicate_memno_regi_error: {
        title: 'target 행에 중복된 출결번호가 있습니다.',
    },
    check_data_regi_error: { title: 'target 행에 입력사항을 확인해주세요.' },
    failed_change_reciept: { title: '등록된 지출증빙 정보는 변경이 불가합니다.' },
    error_reload: { title: '오류가 발생하였습니다.\r\n페이지를 새로고침합니다.' },
    success_send_certification_number: {
        title: '입력하신 휴대폰 번호로 인증번호를 발송하였습니다.',
    },
    failed_send_certification_number: {
        title: '문의하신 고객님의 정보는 존재하지 않습니다.',
    },
    certification_time_over: { title: '인증 시간이 종료되었습니다.' },
    success_send_certification_number2: {
        title: '선택된 휴대폰 번호로 인증번호를 발송하였습니다.',
    },
    failed_certification: { title: '인증번호가 틀렸습니다.' },
    failed_certification2: { title: '인증번호가 올바르지 않습니다.' },
    failed_certification3: {
        title: '5회 이상 틀렸습니다. 인증번호를 재발급 받아주세요.',
    },
    during_time: { title: '1분 이후에 재시도해주세요.' },
    include_emoji: {
        title: '발송이 불가한 기호가 포함되어 있습니다. 수정 후 발송해주세요',
    },
    accept_image_type: { title: '[jpeg, png] 확장자만 업로드가 가능합니다.' },
    success_cerfication_loginid: {
        title: '고객님의 휴대폰 번호로 로그인 아이디가 전송되었습니다.\r\n확인 후 로그인해주세요.',
    },
    success_cerfication_password: {
        title: '고객님의 휴대폰 번호로 임시 비밀번호가 전송되었습니다.\r\n로그인 후 비밀번호를 변경해주세요.',
    },
    error_post_failed: {
        title: '오류로 인해 요청이 실패되었습니다.\r\n다시 시도해주세요.',
    },
    failed_cancel_payment: { title: '정산 예정금액이 없어 취소 불가합니다.' },
    free_use_all_cnt: {
        title: '무료체험 문자를 모두 사용하셨습니다.\r\n** 메시지 추가 필요 **',
    },
    unused_sms: { title: '메시지 발송이 중단된 상태입니다.' },
    add_multi_room_error: {
        title: '중복 클래스와 현재 클래스를 다르게 설정해주세요.',
    },
    add_multi_room_error2: { title: '같은 중복 클래스는 추가할 수 없습니다.' },
    add_multi_room_error3: {
        title: '중복 선생님과 현재 선생님을 다르게 설정해주세요.',
    },
    add_multi_room_error4: { title: '같은 중복 선생님은 추가할 수 없습니다.' },
    set_bill_info: { title: '세금계산서 정보를 기재해주세요.' },
    check_memno: { title: '올바른 출결번호를 입력해주세요.' },
    already_attendance: { title: '이미 출결된 번호입니다.' },
    unabled_time: {
        title: '하원 가능한 시간이 아닙니다.\r\n출결문자 설정을 확인해주세요.',
    },
    write_password: { title: '비밀번호를 입력해주세요.' },
    check_password: { title: '올바른 비밀번호를 입력해주세요.' },
    check_rooms: { title: '해당 학생의 클래스를 먼저 등록해주세요.' },
    check_rooms_teacher: { title: '클래스를 먼저 등록해주세요.' },
    no_sms_target: { title: '해당 학생의 출결 SMS를 등록해주세요.' },
    add_duplicated_sms_number_error: { title: '중복된 휴대폰 번호가 있습니다.' },
    do_not_search_keyword: { title: '해당 키워드와 관련된 내용이 없습니다.' },
    no_excel_data: { title: '작성 가능한 데이터가 없습니다.' },
    wrong_excel_data: { title: '올바른 양식의 엑셀을 업로드해주세요.' },
    not_use_emoji: { title: '이모지는 입력 불가합니다.' },
    no_permission: { title: '권한이 없습니다.\r\n이전 페이지로 이동합니다.' },
    is_teacher: { title: '권한이 없습니다. 대표 관리자에게 문의해주세요.' },
    only_president: { title: '발신번호 변경은 대표 관리자만 가능합니다.' },
    select_product: { title: '상품을 먼저 선택해주세요.' },
    success_account: {
        title: '결제 신청이 완료되었습니다.\r\n입금확인 이후 원활한 사용이 가능합니다.',
    },
    failed_account: {
        title: '오류로 인해 결제 신청이 실패하였습니다.\r\n다시 시도해주세요.',
    },
    only_number: { title: '숫자만 입력해주세요.' },
    info_update: { title: '비밀번호를 제외한 모든 정보를 입력해주세요.' },
    use_spam: {
        title: '발송제한 키워드가 포함되어 발송 불가합니다.\r\n고객센터에 문의해주세요.',
    },
    ready_service: { title: '서비스 준비중입니다.' },
    font_size_down: { title: '글씨 크기가 작아졌습니다. (target)' },
    font_size_up: { title: '글씨 크기가 커졌습니다. (target)' },
    failed_write_time: { title: '정확한 시각을 입력해주세요.' },
    failed_write_temperature: { title: '정확한 체온을 입력해주세요.' },
    no_quotes: { title: "\uAC80\uC0C9\uB780\uC5D0 [']\uBB38\uC790\uAC00 \uD3EC\uD568\uB420 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4." },
    over_attendance_time: { title: '현재 시간 이전의 시각을 입력해주세요.' },
    resend_sms_remain_sms_num: {
        title: '문자가 재발송되었습니다. \r\n남은 문자 수 target건 남았습니다.',
    },
    resend_alim_remain_sms_num: {
        title: '알림톡이 재발송되었습니다. \r\n남은 문자 수 target건 남았습니다.',
    },
    left_students: {
        title: '현재 클래스에 학생이 존재합니다.\r\n모든 학생의 클래스 변경 후 삭제바랍니다.',
    },
    no_slang: {
        title: '악성 키워드 모니터링으로 발송이 거절되었습니다.\r\n"target"',
    },
    no_slang_create: {
        title: '악성 키워드 모니터링으로 등록이 거절되었습니다.\r\n"target"',
    },
    no_download_list: { title: '다운로드 가능한 target 목록이 없습니다.' },
    create_temp_all: { title: '체온이 일괄 기록되었습니다.' },
    edit_only_manager: {
        title: '해당 메뉴는 관리자만 수정 가능합니다.\r\n대표원장님께 문의하세요.',
    },
    over_num_teachers: { title: '선생님은 최대 10명까지 등록 가능합니다.' },
    change_sms_type: { title: 'target 발송 설정되었습니다.' },
    wait_download_excel: { title: '엑셀 저장중입니다.\r\n잠시만 기다려주세요.' },
    download_account_book: {
        title: '수납대장 저장중입니다.\r\n잠시만 기다려주세요.',
    },
    download_calculate: {
        title: 'target내역 저장중입니다.\r\n잠시만 기다려주세요.',
    },
    complete_download_contents: { title: 'target 다운로드 되었습니다.' },
    update_auto_sms: { title: '수납 메시지 발송이 target되었습니다.' },
    update_account_sms: { title: 'target 발송 설정되었습니다.' },
    update_account_message: { title: '수납 target 내용이 수정되었습니다.' },
    cancel_change_product: { title: '상품변경 신청이 철회되었습니다.' },
    pretest_prihibit: { title: '체험계정은 target' },
    pretest_prihibit2: { title: '체험계정에서는 target' },
    pretest_not_send_sms: { title: '체험하기에서는 문자발송이 제한됩니다.' },
    no_sms_history: { title: '삭제된 발송내역입니다.' },
    error_resend: {
        title: '오류로 인해 재발송이 실패하였습니다.\r\n다시 시도해주세요.',
    },
    suceess_send_join_message: {
        title: '메시지를 발송해드렸습니다.\r\n서비스 가입 후, 수납관리 메뉴를 사용해주세요.',
    },
    success_join_account_member: { title: '청구 대상이 추가되었습니다.' },
    success_update_account_member: { title: '청구 대상이 수정되었습니다.' },
    success_delete_account_member: { title: '청구 대상이 삭제되었습니다.' },
    success_update_account_count: {
        title: 'target 학생의 잔여 출결횟수가 수정되었습니다.',
    },
    set_account_memo: { title: '메모가 설정되었습니다.' },
    set_account_memo_in_bill: { title: '청구서에 메모가 기입되었습니다.' },
    set_account_memo2: { title: '메모가 기입되었습니다.' },
    update_account_memo: { title: '메모가 수정되었습니다.' },
    complete_cancel_payment: { title: '결제가 취소되었습니다.' },
    complete_resend_bill: { title: '청구서가 재발송 되었습니다.' },
    complete_cancel_bill: { title: '발행된 청구서가 취소되었습니다.' },
    add_account_bill: { title: '청구서 내역이 생성되었습니다.' },
    exceed_cancelable_period: { title: '결제일 90일 초과로 취소 불가합니다.' },
    success_attendance: { title: '해당 학생이 target처리 되었습니다.' },
    not_change_cname: {
        title: '기본 설정의 관리자 정보 > 학원 정보에서\r\n학원명을 변경하실 수 있습니다.',
    },
    under_alert: { title: 'target까지 설정이 가능합니다.' },
    not_change_first_send: { title: '최초 발송은 해제할 수 없습니다.' },
    admin_phone_error: { title: '관리자의 번호로 대상을 등록할 수 없습니다.' },
    payment_more: { title: '입금기한이 3일 연장되었습니다.' },
    write_another_number: {
        title: '해당 학생 정보에 이미 등록된 번호입니다.\r\n다른 번호를 입력해주세요.',
    },
    view_count: { title: '횟수 target회 (잔여 target2건)' },
    reserved_account: { title: '청구서가 재발송되었습니다.' },
    info_account_pretest: {
        title: '테스트 발송은 실 결제가 불가하지만,<br />청구서 발송 전체 과정을 확인하실 수 있습니다.',
    },
    send_account_pretest: { title: '청구서를 메시지로 보내드렸습니다.' },
    already_send_account_pretest: {
        title: '1일 1회 발송으로, 수신된 메시지를 확인해주세요.',
    },
    is_resended_bill: { title: '이미 청구서가 재발행되었습니다.' },
    only_payment: { title: '출결버스 정식 가입 후 이용 가능합니다.' },
    only_payment_setup: { title: '출결버스 정식 가입 후 설정 가능합니다.' },
    download_excel: { title: 'target이 다운로드 되었습니다.' },
    check_attendance_count: { title: 'target 남았습니다.' },
    no_search_student: { title: '검색결과가 존재하지 않습니다.' },
    update_uyear: { title: '학년이 일괄 수정되었습니다.' },
    pretest_charge_cash: {
        title: '체험 계정이므로 신청이 불가합니다.\r\n선생님 계정에서 충전신청 부탁드립니다.',
    },
    notice_history_manual: {
        title: '자동결제 외 결제된 내역을 입력해주세요.\r\nex) 현금/지역화폐 등',
    },
    notice_before_open: {
        title: '결제 서비스 승인 대기중입니다. (신청 후 영업일 기준 2~3일 소요)',
    },
    over_lms_title: {
        title: '최대 입력 글자수에 도달하였습니다.',
    },
    update_all_content: {
        title: '내용이 일괄 수정되었습니다.',
    },
    zero_amount: {
        title: '올바른 금액을 입력해주세요.',
    },
    set_account_startdate: {
        title: '청구 시작일을 선택해주세요.',
    },
    wrong_select_date: {
        title: '조회 일자를 정확히 선택해주세요.',
    },
    wrong_select_date_over_preiod: {
        title: '기간 조회는 최대 6개월 이내로만 가능합니다.',
    },
    cancel_account_alim: {
        title: '청구서 안내 알림톡은 예약취소가 불가합니다.',
    },
    refresh_attendance: { title: '출결 현황이 업데이트 되었습니다.' },
    logout_using_modal_pc: { title: '로그아웃되어 PC출결이 종료됩니다.' },
    using_modal_pc: { title: '출결모드 실행중입니다.' },
    already_account_receipt: { title: '이미 현금영수증이 발급된 내역입니다.' },
    already_cancel_account_receipt: {
        title: '이미 현금영수증이 취소된 내역입니다.',
    },
    success_add_account_receipt: { title: '현금영수증이 발급되었습니다.' },
    success_cancel_account_receipt: { title: '현금영수증이 취소되었습니다.' },
    alert_add_account_receipt: {
        title: '현금영수증은 결제일 기준 2일 이후부터 발급 가능합니다.',
    },
    alert_update_account_costday: { title: 'target 항목으로 수정되었습니다.' },
    account_alime_direct_over: { title: '30자 이내로 작성해주세요.' },
    tommorrow_cancel_receipt: {
        title: '해당 현금영수증은 1일 후에 취소가 가능합니다.',
    },
    multi_complete_account: { title: '일괄 납부완료처리가 되었습니다.' },
    send_spam_notice: { title: '스팸 해제 방법이 문자로 발송되었습니다.' },
    sms_over_size: { title: '500KB 이하의 파일을 업로드해주세요.' },
    sms_error_type: { title: 'jpg 확장자만 업로드 가능합니다.' },
    sms_over_width: { title: '이미지 너비가 1000px를 초과하였습니다.' },
    sms_over_height: { title: '이미지 높이가 1000px를 초과하였습니다.' },
    error_count_multiple: { title: '모든 정보값을 입력해주세요.' },
    error_select_message: { title: '청구서 메시지 수신자를 입력해주세요.' },
    error_select_student: { title: '학생을 선택해주세요.' },
    error_select_period_format: { title: '기간 형식을 입력해주세요.' },
    error_select_charge_date: { title: '청구일을 입력해주세요.' },
    error_select_presendday: { title: '사전 발송일을 입력해주세요.' },
    error_select_cost: { title: '교습비를 입력해주세요.' },
    error_select_content: { title: '청구 내용을 입력해주세요.' },
    error_select_payment_due_date: { title: '납부기한을 입력해주세요.' },
    error_select_billing_format: { title: '청구 방식을 입력해주세요.' },
    error_select_frequency_linkage: { title: '출결횟수와 연동을 입력해주세요.' },
    error_select_account_count: { title: '출결 횟수를 입력해주세요.' },
    error_select_multiple: {
        title: '다중 선택 시, 항목과 금액은 같은 조건으로 발행됩니다.',
    },
    error_input_zero: { title: '1 이상의 숫자를 입력해주세요.' },
    counsel_out_range: { title: '조회는 최대 60일 이내로만 가능합니다.' },
    counsel_excel_empty: { title: '해당 기간내에 상담일지가 없습니다.' },
};
export var check_contents = {
    duplicate_ID: { title: '중복된 아이디가 있습니다.' },
    duplicate_memno: { title: '중복된 출결번호가 있습니다.' },
    impossible_cash_receipt: {
        title: '발행 가능한 내역이 없습니다.',
        sub_title: '<span class="text-gray-1">(현금영수증은 최근 5일 이내 결제건만 발행 가능)</span>',
    },
    wrong_select_date_over_preiod: {
        title: '기간 조회는 최대 6개월 이내로만 가능합니다.',
    },
    wrong_select_date_over_preiod2: {
        title: '기간 조회는 50일 이내로만 가능합니다.',
    },
    reservation_error: { title: '현재 시간에서 최소 10분 뒤로 설정해주세요.' },
    send_sms_target_error: { title: '한 명 이상의 대상을 추가해주세요.' },
    send_sms_message_error: { title: '1자 이상의 메시지를 입력해주세요.' },
    miss_required: { title: '필수사항을 입력해주세요.' },
    before_today_attendance: {
        title: '당일 이전의 출결 기록만 조회 가능합니다.',
    },
    before_today_temperature: {
        title: '당일 이전의 체온 기록만 조회 가능합니다.',
    },
    no_tmp_list: {
        title: '설정된 출석 요일이 아닙니다.\r\n출결 기록을 입력하려면 클래스를 선택하세요.',
    },
    no_fee: {
        title: '22년 12월 10일까지 가입비는 없습니다.',
        sub_title: '<span class="text-red">(가입 간 표기된 가입비는 무시하셔도 됩니다.)</span>',
    },
};
