var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, Typography } from '@mui/material';
import { ButtonGroup, Button } from '@mui/material';
import { NOT_USE_TAB } from '../../utils/constants';
// ButtonGroup Style
var StyledButtonGroup = styled(ButtonGroup)(function (props) { return ({
    width: '100%',
    height: props.height ? props.height : 'auto',
    margin: 'auto',
}); });
var StyledLeftButton = styled(Button)(function (props) { return ({
    textAlign: 'center',
    border: 'none',
    boxShadow: 'none',
    '&.MuiButtonGroup-grouped': {
        minWidth: '0',
        padding: '0',
    },
    '&:active': {
        border: 'none',
        boxShadow: 'none',
    },
    '&:hover': {
        border: 'none',
        boxShadow: 'none',
    },
    fontFamily: "'Apple SD Gothic Neo', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue'",
    width: '50%',
    marginLeft: '0',
    fontWeight: '500',
    fontSize: '1rem',
    // sapn tag style
    span: {
        fontWeight: 'normal',
    },
    //
    '&.MuiButton-outlined': {
        width: '50%',
        backgroundColor: props.className === 'attendance' ? '#1bd05b' : '#fff',
        border: "".concat(props.className === 'attendance' ? '1px solid #1bd05b' : '1px solid rgba(0, 0, 0, 0.2)'),
        borderRight: 'none',
        color: props.className === 'attendance' ? '#fff' : 'rgb(159, 159, 159)',
        '&:hover': {
            border: "".concat(props.className === 'attendance' ? '1px solid #1bd05b' : '1px solid rgba(0, 0, 0, 0.2)'),
            borderRight: 'none',
        },
    },
}); });
// ButtonRight Style
var StyledRightButton = styled(Button)(function (props) {
    return {
        '&.MuiButtonGroup-grouped:not(:first-of-type)': {
            textAlign: 'center',
            border: props.className === 'attendance' || props.className === 'counseling' ? '1px solid rgb(255, 214, 0)' : 'none',
            backgroundColor: props.className === 'attendance' ? 'rgb(255, 214, 0)' : '',
            boxShadow: 'none',
            '&.MuiButtonGroup-grouped': {
                minWidth: '0',
                padding: '0',
            },
            '&:active': {
                border: 'none',
                boxShadow: 'none',
            },
            '&:hover': {
                border: props.className === 'attendance' || props.className === 'counseling' ? '1px solid rgb(255, 214, 0)' : 'none',
                boxShadow: 'none',
            },
            fontFamily: "'Apple SD Gothic Neo', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue'",
            width: '50%',
            marginLeft: 0,
            fontWeight: '500',
            fontSize: '1rem',
            // span tag style
            span: {
                fontWeight: 'normal',
            },
            //
            '&.MuiButton-outlined': {
                width: '50%',
                border: "".concat(props.className === 'attendance' || props.className === 'counseling'
                    ? '1px solid rgb(255, 214, 0)'
                    : '1px solid rgba(0, 0, 0, 0.2)'),
                color: props.className === 'attendance' ? '#fff' : 'rgb(159, 159, 159)',
                backgroundColor: props.className === 'attendance' ? 'rgb(255, 214, 0)' : '#fff',
                borderLeft: 'none',
                span: {
                    fontWeight: 'normal',
                },
                '&:hover': {
                // border: `${
                //   props.className === 'attendance' || props.title === 'counseling'
                //     ? '1px solid rgb(46, 206, 115)'
                //     : '1px solid rgba(0, 0, 0, 0.2)'
                // }`,
                // borderLeft: 'none',
                },
                '&.Mui-disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                    border: 'none',
                    borderLeft: '3px solid var(--gray-3)',
                },
            },
        },
    };
});
// type(props)의 Type 정의
var pc = {
    size: {},
    color: ['green', 'gray_off'],
    text: ['ON', 'OFF'],
};
var pc2 = {
    size: {},
    color: ['green', 'yellow_1'],
    text: ['등원 내역', '하원 내역'],
};
var sms = {
    size: {},
    color: ['green', 'yellow_1'],
    text: ['SMS 문자', '알림톡'],
};
var payment = {
    size: {},
    color: ['green', 'red'],
    text: ['ON', 'OFF'],
};
var message_content = {
    size: {},
    color: ['green', 'green'],
    text: ['ON', 'OFF'],
};
var message_setting = {
    size: {},
    color: ['yellow_1', 'green'],
    text: ['알림톡', 'SMS 문자'],
};
var attendance = {
    size: {},
    color: ['green', 'green'],
    text: ['출결분석 출력', '출결내역 저장'],
};
var counseling = {
    size: {},
    color: ['green', 'green'],
    text: ['상담일지 출력', '상담일지 저장'],
};
var group_button_type = { pc: pc, pc2: pc2, sms: sms, payment: payment, message_content: message_content, message_setting: message_setting, attendance: attendance, counseling: counseling };
// 컴포넌트 로직
function CustomButtonGroup(props) {
    var type = group_button_type[props.type];
    var default_type = props.defaultType;
    var disabled = props.disabled;
    var height = props.height;
    var sx = props.sx;
    var index = props.index;
    return (_jsxs(StyledButtonGroup, __assign({ id: 'button_group', className: 'button_group', disableRipple: true, disabled: disabled, height: height, tabIndex: NOT_USE_TAB }, { children: [_jsxs(StyledLeftButton, __assign({ variant: default_type === 'left' ? 'contained' : 'outlined', color: default_type === 'left' ? type.color[0] : props.type === 'attendance' ? 'green' : 'gray_1', sx: sx, style: { borderRight: 'none' }, onClick: function () { return props.onClick('left', default_type, index); }, className: type.text[0] === '출결분석 출력' ? 'attendance' : '', tabIndex: NOT_USE_TAB }, { children: [type.text[0], type.text[0] === '출결분석 출력' ? (_jsx(Typography, __assign({ component: 'span', sx: { marginLeft: '4px' } }, { children: "(\uD559\uC0DD)" }), void 0)) : null] }), void 0), _jsxs(StyledRightButton, __assign({ title: props.type, variant: default_type === 'right' ? 'contained' : 'outlined', color: default_type === 'right'
                    ? type.color[1]
                    : props.type === 'attendance' || props.type === 'counseling'
                        ? 'green'
                        : 'gray_1', sx: sx, style: { borderLeft: 'none' }, onClick: function () { return props.onClick('right', default_type, index); }, className: ['상담일지 저장', '출결내역 저장'].includes(type.text[1]) ? 'attendance' : '', tabIndex: NOT_USE_TAB }, { children: [type.text[1], type.text[1] === '출결내역 저장' ? (_jsx(Typography, __assign({ component: 'span', sx: { marginLeft: '4px' } }, { children: "(\uC804\uCCB4\uBC18)" }), void 0)) : null] }), void 0)] }), void 0));
}
export default CustomButtonGroup;
