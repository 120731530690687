var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState, useEffect } from 'react';
import ModalDefault from './ModalDefault';
import CalendarPicker from '@mui/lab/CalendarPicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Button from '../button/Button';
import { d_flex_center, d_flex, dir_column } from '../../styles/common';
import { styled, Box } from '@mui/material';
import { ModalContext } from '../../provider/ModalProvider';
import { setLocaleDateString } from '../../utils/tools';
var CustomDatePicker = styled(CalendarPicker)(function (_a) {
    var theme = _a.theme, view = _a.view;
    var year_style = {};
    if (view === 'year') {
        year_style = {
            '& > div': {
                '&:first-of-type': {
                    display: 'none',
                },
                '&:last-of-type': {
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '& > div': {
                        '& > div': {
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            margin: '0',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            '& > div': {
                                width: '100%',
                                button: __assign(__assign({ height: '3.25rem', width: '100%', borderRadius: '0', margin: '0', padding: '1rem 0', fontSize: '1.2rem', fontWeight: '500' }, d_flex_center), { '&.Mui-selected': {
                                        backgroundColor: '#e0e0e0',
                                        color: theme.palette.black.main,
                                    } }),
                            },
                        },
                    },
                },
            },
        };
    }
    return __assign(__assign({ width: '24rem', height: '23.714rem', '&::-webkit-scrollbar': {
            display: 'none',
        }, '& > div': {
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            fontSize: '1rem !important',
            marginTop: '15px',
            '&:first-of-type': {
                position: 'relative',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                '& > div': {
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '&:first-of-type': {
                        position: 'relative',
                        right: '0.95rem',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        '& > div': {
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            '&:first-of-type': {
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                width: '5rem',
                                textAlign: 'center',
                            },
                        },
                        button: {
                            display: 'none',
                        },
                    },
                    '&:last-of-type': {
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0 0.5rem',
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        transform: 'translateY(-50%)',
                        '& > button': {
                            padding: '0',
                            width: '2.5rem',
                            height: '2.5rem',
                        },
                    },
                },
            },
            '.PrivatePickersSlideTransition-root': {
                minHeight: '238px',
                height: '238px',
                '.MuiPickersDay-root': {
                    '&.Mui-selected': {
                        backgroundColor: 'transparent',
                        color: 'rgba(0, 0, 0, 0.87)',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        },
                    },
                },
            },
            '&:last-of-type': {
                height: 'calc(100% - 30px)',
                '& > div': {
                    height: '100%',
                    '& > div:first-of-type': {
                        height: '2.5rem',
                        gap: '0.1rem 0rem',
                        '& > span': {
                            width: '2.5rem',
                            height: '2.5rem',
                        },
                    },
                    '& > div:last-of-type': {
                        // height: 'calc(100% - 3rem)',
                        height: 'auto',
                        '& > div': {
                            '& > div': {
                                margin: '0',
                                gap: '0.1rem 0rem',
                                '& > div': {
                                    '& > div': {
                                        width: '2.5rem',
                                        height: '2.5rem',
                                    },
                                    '& > button': {
                                        width: '2.5rem',
                                        height: '2.5rem',
                                    },
                                },
                            },
                        },
                    },
                },
            },
        } }, year_style), { margin: '0' });
});
var StyledModalCalendar = styled(Box)(function (props) { return (__assign(__assign({ minWidth: '24rem', backgroundColor: props.theme.palette.white.main }, d_flex_center), dir_column)); });
var StyledTitle = styled(Box)(function (props) { return (__assign(__assign({ width: '100%', height: '6rem', backgroundColor: '#FF453B !important', color: props.theme.palette.white.main, padding: '0 1rem', justifyContent: 'center' }, d_flex), dir_column)); });
function ModalCalendar() {
    var modal_calendar = useContext(ModalContext).modal_calendar;
    var _a = useState(null), value = _a[0], setValue = _a[1];
    var _b = useState(setLocaleDateString(new Date(), 'kor')), localeDate = _b[0], setLocaleDate = _b[1];
    var _c = useState(''), curYear = _c[0], setCurYear = _c[1];
    var _d = useState(new Date('9999-12-31')), maxDate = _d[0], setMaxDate = _d[1];
    var _e = useState(null), defaultMonth = _e[0], setDefaultMonth = _e[1];
    useEffect(function () {
        if (modal_calendar.data.visible) {
            if (modal_calendar.data.type === 'year') {
                setMaxDate(new Date());
            }
            else {
                setMaxDate(modal_calendar.data.max);
                if (modal_calendar.data.default) {
                    setDefaultMonth(modal_calendar.data.default);
                }
            }
            if (modal_calendar.data.date[modal_calendar.data.type] ||
                modal_calendar.data.date[modal_calendar.data.type] === null) {
                setValue(modal_calendar.data.date[modal_calendar.data.type]);
                setLocaleDate(setLocaleDateString(modal_calendar.data.date[modal_calendar.data.type], 'kor'));
            }
        }
    }, [modal_calendar.data.visible]);
    useEffect(function () {
        if (localeDate) {
            setCurYear(localeDate.slice(0, 4));
        }
    }, [localeDate]);
    var handleChange = function (date, type) {
        setValue(date);
        if (type == 'date') {
            setLocaleDate(setLocaleDateString(new Date("".concat(date)), 'kor'));
            modal_calendar.setModalCalendarDataDateType(new Date("".concat(date)), modal_calendar.data.type);
            modal_calendar.closeModalCalendar();
        }
    };
    var last_year = new Date().getFullYear() - 1;
    return (_jsx(_Fragment, { children: _jsx(ModalDefault, __assign({ visible: modal_calendar.data.visible, onClose: modal_calendar.closeModalCalendar }, { children: _jsxs(StyledModalCalendar, { children: [_jsxs(StyledTitle, { children: [_jsx(Box, __assign({ component: 'h4', sx: { color: 'gray_3.main' } }, { children: curYear }), void 0), _jsx(Box, __assign({ component: 'h2' }, { children: localeDate }), void 0)] }, void 0), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDateFns }, { children: _jsx(CustomDatePicker, { defaultCalendarMonth: defaultMonth, view: modal_calendar.data.type === 'year' ? 'year' : 'day', minDate: modal_calendar.data.type === 'year'
                                ? new Date("".concat(last_year))
                                : modal_calendar.data.min
                                    ? modal_calendar.data.min
                                    : null, maxDate: maxDate, date: value, onChange: function (date) { return handleChange(date, 'date'); }, onMonthChange: function (date) { return handleChange(date, 'month'); }, allowSameDateSelection: true }, void 0) }), void 0), _jsx(Button, __assign({ color: 'black', onClick: modal_calendar.closeModalCalendar, sx: { width: 1, pt: '1rem', pb: '1rem' } }, { children: "\uCDE8\uC18C" }), void 0)] }, void 0) }), void 0) }, void 0));
}
export default ModalCalendar;
