var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled, Box } from '@mui/material';
import theme from '../theme';
import DropdownNormal from '../components/dropdown/DropdownNormal';
var d_block = { display: 'block' };
var d_flex = { display: 'flex' };
var d_flex_center = { display: 'flex', alignItems: 'center', justifyContent: 'center' };
var d_flex_space_between = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' };
var d_flex_space_evenly = { display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' };
var d_flex_space_around = { display: 'flex', alignItems: 'center', justifyContent: 'space-around' };
var d_flex_start = { display: 'flex', alignItems: 'center', justifyContent: 'flex-start' };
var d_flex_end = { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' };
var ignore_drag = { userSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', WebkitUserSelect: 'none' };
var dir_row = { flexDirection: 'row' };
var dir_column = { flexDirection: 'column' };
var Deco = styled(Box)({
    display: 'inline-block',
    marginRight: '0.5rem',
    borderLeft: "1px dotted ".concat(theme.palette.gray_1.main),
    borderBottom: "1px dotted ".concat(theme.palette.gray_1.main),
    transform: 'translateY(-50%)',
});
export var Bar = styled(Box)(function (props) { return ({
    width: '2px',
    height: '1rem',
    marginRight: '0.5rem',
    marginLeft: props.$marginLeft ? '0.5rem' : '',
    backgroundColor: props.$bgColor ? props.theme.palette[props.$bgColor].main : props.theme.palette.black.main,
}); });
export var Border = styled(Box)(function (props) { return ({
    borderWidth: props.$borderWidth,
    borderStyle: props.$borderStyle,
    borderColor: props.theme.palette[props.$borderColor].main,
}); });
var StyledModalWrapper = styled(Box)(function (props) { return (__assign(__assign({ maxWidth: '480px', width: '35.7rem', borderRadius: '4px', lineHeight: '2rem', backgroundColor: props.theme.palette.white.main }, d_flex_center), dir_column)); });
var StyledModalTitle = styled(Box)(function (props) {
    var _a, _b;
    return (__assign(__assign(__assign((_a = { width: '100%' }, _a[props.theme.breakpoints.up('xxl')] = {
        fontSize: '1.1rem',
    }, _a[props.theme.breakpoints.down('xxl')] = {
        fontSize: '1.2rem',
    }, _a['.delete_contents, .delete_contents b'] = (_b = {},
        _b[props.theme.breakpoints.up('xxl')] = {
            fontSize: '1.1rem',
        },
        _b[props.theme.breakpoints.down('xxl')] = {
            fontSize: '1.2rem',
        },
        _b), _a['.delete_contents b'] = {
        fontWeight: 'bold',
    }, _a.whiteSpace = 'pre-wrap', _a.textAlign = 'center', _a.color = 'rgba(0, 0, 0, 0.87)', _a.padding = '32px 16px', _a), d_flex_center), dir_column), { '& > span': {
            '&:last-of-type': {
                fontSize: '1rem',
                lineHeight: '1.45rem',
            },
        } }));
});
var StyledModalUtil = styled(Box)(function (props) { return (__assign({ width: '100%', height: '52px', borderTop: "1px solid ".concat(props.theme.palette.gray_3.main) }, d_flex_center)); });
var StyledLine = styled(Box)(function (props) { return ({
    width: '1px',
    height: '100%',
    backgroundColor: props.theme.palette.gray_3.main,
}); });
var StyledDropdownNormal = styled(DropdownNormal)(function () { return ({
    backgroundColor: '#00bfff',
    color: '#ffffff',
    // border: 'none',
    '&:hover': {
        backgroundColor: '#00bfff !important',
    },
}); });
var StyledUtilWrap = styled(Box)(function (props) {
    return __assign(__assign({ width: props.$width ? props.$width : '100%', height: '2.5rem', marginBottom: '0.5rem', marginTop: props.marginTop === 'true' ? '0.5rem' : '0' }, d_flex_space_between), { '.regi': {
            '&.edit-page': {
                position: 'fixed',
                top: 'calc(9.5rem + 8px)',
                height: '2.5rem',
                '&.student': {
                    top: 'calc(12.5rem)',
                },
            },
        }, '.require_text': {
            position: 'fixed',
            height: '2.5rem',
            top: 'calc(12.5rem)',
            width: '100%',
        }, '.scroll_box': {
            position: 'fixed',
            height: '3rem',
            width: '3rem',
            bottom: '12px',
            right: '12px',
            '& > div': {
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                backgroundColor: '#fafafa',
                border: '1px solid',
                borderColor: '#e4e4e4',
                borderRadius: '4px',
                fontSize: '0.9rem',
                cursor: 'pointer',
                svg: {
                    marginTop: '-0.2rem',
                    width: '1rem',
                    height: '1rem',
                    strokeWidth: 0.5,
                },
            },
        } });
});
var StyledUtil = styled(Box)(function (props) { return (__assign({ width: props.$width, height: '100%', alignItems: 'center', justifyContent: props.$direction === 'left' ? 'flex-start' : 'flex-end' }, d_flex)); });
var CloseButton = styled(Box)(function (_a) {
    var theme = _a.theme;
    return (__assign({ position: 'absolute', top: '1rem', right: '1.2rem', zIndex: 2, backgroundColor: '#c9c9c9', width: '2rem', height: '2rem', borderRadius: '50%', padding: 0, cursor: 'pointer', svg: {
            width: '1.7rem',
            height: '1.7rem',
            strokeWidth: 30,
        } }, d_flex_center));
});
var magazine_subject_colors = [
    {
        id: '10',
        color: '#FF453B',
    },
    {
        id: '20',
        color: '#FF7C26',
    },
    {
        id: '30',
        color: '#FFB22D',
    },
    {
        id: '40',
        color: '#3ACA4E',
    },
    {
        id: '50',
        color: '#2F6BC1',
    },
    {
        id: '60',
        color: '#BF5EFB',
    },
];
export { d_block, d_flex, d_flex_center, d_flex_start, d_flex_end, d_flex_space_between, d_flex_space_evenly, d_flex_space_around, ignore_drag, dir_row, dir_column, Deco, StyledModalWrapper, StyledModalTitle, StyledModalUtil, StyledLine, StyledUtilWrap, StyledDropdownNormal, StyledUtil, CloseButton, magazine_subject_colors, };
