var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { ModalContext } from '../../provider/ModalProvider';
import { d_flex_center, dir_column } from '../../styles/common';
import { styled, Box } from '@mui/material';
var StyledModalAlertWrap = styled(Box)(function (props) { return ({
    backgroundColor: 'transparent',
    borderRadius: '4px',
    overflow: 'hidden',
    position: 'fixed',
    top: '15.5%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1301,
    opacity: props.visible ? 1 : 0,
    visibility: props.visible ? 'visible' : 'hidden',
    transition: 'opacity 0.5s, visibility 0.5s',
    paddingRight: '2rem',
}); });
var StyledModalAlert = styled(Box)(function (props) {
    var _a;
    return (_a = {
            position: 'relative',
            minWidth: 'calc(100% + 2rem)',
            maxWidth: '480px',
            minHeight: '20px',
            borderRadius: '30px',
            padding: '6px 16px',
            backgroundColor: '#404040'
        },
        _a[props.theme.breakpoints.up('xxl')] = {
            fontSize: '1.1rem',
        },
        _a[props.theme.breakpoints.down('xxl')] = {
            fontSize: '1.2rem',
        },
        _a.whiteSpace = 'pre-wrap',
        _a);
});
var StyledTitle = styled(Box)(function (props) {
    var _a;
    return (__assign(__assign(__assign((_a = { width: '100%', height: '100%', textAlign: 'center', margin: '0.4rem 0 0.4rem 0' }, _a[props.theme.breakpoints.up('xxl')] = {
        fontSize: '0.95rem',
    }, _a[props.theme.breakpoints.down('xxl')] = {
        fontSize: '1.2rem',
    }, _a.lineHeight = '1.5', _a.color = '#fff !important', _a.caretColor = '#fff !important', _a), d_flex_center), dir_column), { '.sub_title': {
            fontWeight: '300',
            fontSize: '1.1rem',
        } }));
});
function ModalAlert() {
    var modal_alert = useContext(ModalContext).modal_alert;
    var _a = useState(modal_alert.data.visible), isVisible = _a[0], setIsVisible = _a[1];
    useEffect(function () {
        if (modal_alert.data.visible) {
            setIsVisible(true);
        }
        else {
            var timer_1 = setTimeout(function () {
                setIsVisible(false);
            }, 500);
            return function () { return clearTimeout(timer_1); };
        }
    }, [modal_alert.data.visible]);
    return isVisible ? (_jsx(StyledModalAlertWrap, __assign({ visible: modal_alert.data.visible, id: 'modal_alert' }, { children: _jsx(StyledModalAlert, { children: _jsx(StyledTitle, { dangerouslySetInnerHTML: { __html: modal_alert.data.title } }, void 0) }, void 0) }), void 0)) : null;
}
export default ModalAlert;
