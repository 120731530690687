import { validatePassword } from './tools';
var validation = {
    phone: function (value) {
        if (value.length != 0) {
            var reg_phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
            if (reg_phone.test(value)) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    },
    phone_no_hipen: function (value) {
        if (value.length != 0) {
            var reg_phone = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;
            if (reg_phone.test(value)) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    },
    date: function (value) {
        var reg_date = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
        if (value.length != 0) {
            if (reg_date.test(value)) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    },
    password: function (value) {
        if (value.length != 0) {
            var isPassword = validatePassword(value);
            if (!isPassword) {
                return false;
            }
        }
        return true;
    },
    login_id: function (value) {
        if (value.length != 0) {
            var reg_id = /^[A-za-z0-9_]*$/g;
            if (reg_id.test(value)) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    },
    memno: function (value) {
        if (value.length != 0) {
            var reg_memno = /^[0-9]{1,6}$/g;
            if (reg_memno.test(value)) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    },
    time: function (value) {
        if (value.length != 0) {
            var time_format = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
            if (time_format.test(value)) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    },
    temperature: function (value) {
        if (value.length != 0) {
            var temperature_format = /^(([3-4][0-9]).([0-9])|([3-4][0-9]))$/;
            if (temperature_format.test(value)) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    },
    email: function (value) {
        if (value.length != 0) {
            var email_format = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (email_format.test(value)) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    },
    emoji: function (value) {
        // const emoji_regex =
        //   // /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
        //   /(?:[\u00A9\u203C\u2049\u2139\u231A\u231B\u2328\u23CF\u23E9-\u23F3\u23F8-\u23FA\u24C2\u25AA-\u25AB\u25FB-\u25FE\u2600-\u2604\u2611\u2614-\u2615\u2618\u261D\u2620\u2622-\u2623\u2626\u262A\u262E-\u262F\u2638-\u263A\u2648-\u2653-\u2666\u267B\u267F\u2692-\u2697\u2699\u269B-\u269C\u26A0-\u26A1\u26AA-\u26AB\u26B0-\u26B1\u26BD-\u26BE\u26C4-\u26C5\u26C8\u26CE-\u26CF\u26D1\u26D3-\u26D4\u26E9-\u26EA\u26F0-\u26F5\u26F7-\u26FA\u26FD\u2702\u2705\u2708-\u270D\u270F\u2712\u2714\u2716\u271D\u2721\u2728\u2733-\u2734\u2744\u2747\u274C\u274E\u2753-\u2755\u2757\u2763-\u2764\u2795-\u2797\u27A1\u27B0\u27BF\u2934-\u2935\u2B05-\u2B07\u2B1B-\u2B1C\u2B50\u2B55\u3030\u303D\u3297\u3299]|(?:\uD83C[\uDC04\uDCCF\uDD70-\uDD71\uDD7E-\uDD7F\uDD8E\uDD91-\uDD9A\uDDE6-\uDDFF\uDE01-\uDE02\uDE1A\uDE2F\uDE32-\uDE3A\uDE50-\uDE51\uDF00-\uDF21\uDF24-\uDF93\uDF96-\uDF97\uDF99-\uDF9B\uDF9E-\uDFF0\uDFF3-\uDFF5\uDFF7-\uDFFF]|\uD83D[\uDC00-\uDCFD\uDCFF-\uDD3D\uDD49-\uDD4E\uDD50-\uDD67\uDD6F-\uDD70\uDD73-\uDD7A\uDD87\uDD8A-\uDD8D\uDD90\uDD95-\uDD96\uDDA4-\uDDA5\uDDA8\uDDB1-\uDDB2\uDDBC\uDDC2-\uDDC4\uDDD1-\uDDD3\uDDDC-\uDDDE\uDDE1\uDDE3\uDDE8\uDDEF\uDDF3\uDDFA-\uDE4F\uDE80-\uDEC5\uDECB-\uDED2\uDEE0-\uDEE5\uDEE9\uDEEB-\uDEEC\uDEF0\uDEF3-\uDEF6]|\uD83E[\uDD10-\uDD1E\uDD20-\uDD27\uDD30\uDD33-\uDD3A\uDD3C-\uDD3E\uDD40-\uDD45\uDD47-\uDD4B\uDD50-\uDD5E\uDD80-\uDD91\uDDC0]))/g;
        // const emoji_regex =
        //   /^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]))[^`~!+,!@#$%^&*();\/|<>"0-9=[\]{}_☺]){1,255}$/g;
        var emoji_regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g;
        // const date_regex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
        // const date_time_regex =
        //   /([0-2][0-9]{3})-([0-1][0-9])-([0-3][0-9]) ([0-5][0-9]):([0-5][0-9]):([0-5][0-9])(([\\-\\+]([0-1][0-9])\\:00))?/;
        // const specific_char_regex = /[\{\}\[\]\/\?\.\,\;\:\|\)\*\~\`\!\^\-\_\+\<\>\@\#\$\%\&\\\=\(\'\"]/gi;
        var res = true;
        if (typeof value == 'string') {
            if (
            // emoji_regex.test(value) ||
            // (!date_regex.test(value) && !date_time_regex.test(value) && !specific_char_regex.test(value))
            emoji_regex.test(value)) {
                return false;
            }
        }
        else {
            for (var _i = 0, _a = Object.entries(value); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], val = _b[1];
                if (typeof val == 'string') {
                    if (
                    // emoji_regex.test(val) ||
                    // (!date_regex.test(val) && !date_time_regex.test(val) && !specific_char_regex.test(val))
                    emoji_regex.test(val)) {
                        return false;
                    }
                }
                else if (val == null || (typeof val !== 'object' && typeof val !== 'string')) {
                    if (emoji_regex.test("".concat(val))) {
                        return false;
                    }
                }
                else {
                    var vali = validation.emoji(val);
                    if (vali == false) {
                        res = false;
                        break;
                    }
                }
            }
        }
        return res;
    },
    name: function (value) {
        var notBeIncludedInTheNameArray = [';'];
        var isInvalidName = notBeIncludedInTheNameArray.some(function (notBeIncludedInTheNameitem) {
            return value.includes(notBeIncludedInTheNameitem);
        });
        if (isInvalidName) {
            return false;
        }
        else {
            return true;
        }
    },
};
export default validation;
