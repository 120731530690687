var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import ModalDefault from './ModalDefault';
import { ModalContext } from '../../provider/ModalProvider';
import { IoCloseSharp } from 'react-icons/io5';
import { d_flex_center, dir_column, CloseButton } from '../../styles/common';
import { styled, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
var StyledModalAlertWrap = styled(Box)(function (props) { return ({
    backgroundColor: '#fff',
    borderRadius: '4px',
    overflow: 'hidden',
}); });
var StyledModalAlert = styled(Box)(function (props) {
    var _a;
    return (_a = {
            position: 'relative',
            width: '35.7rem',
            maxWidth: '480px',
            borderRadius: '4px',
            padding: '32px 16px',
            backgroundColor: '#000',
            opacity: '0.75'
        },
        _a[props.theme.breakpoints.up('xxl')] = {
            fontSize: '1.1rem',
        },
        _a[props.theme.breakpoints.down('xxl')] = {
            fontSize: '1.2rem',
        },
        _a.whiteSpace = 'pre-wrap',
        _a);
});
var StyledTitle = styled(Box)(function (props) {
    var _a;
    return (__assign(__assign(__assign((_a = { width: '100%', height: '100%', textAlign: 'center' }, _a[props.theme.breakpoints.up('xxl')] = {
        fontSize: '1.1rem',
    }, _a[props.theme.breakpoints.down('xxl')] = {
        fontSize: '1.2rem',
    }, _a.lineHeight = '2rem', _a.color = '#fff !important', _a.caretColor = '#fff !important', _a), d_flex_center), dir_column), { '.sub_title': {
            fontWeight: '300',
            fontSize: '1.1rem',
        } }));
});
function ModalAlertOrigin() {
    var modal_alert_origin = useContext(ModalContext).modal_alert_origin;
    var location = useLocation();
    return (_jsx(_Fragment, { children: _jsx(ModalDefault, __assign({ visible: modal_alert_origin.data.visible, opacity: 46, onClose: function () {
                return;
            } }, { children: _jsx(StyledModalAlertWrap, __assign({ id: 'modal_alert' }, { children: _jsxs(StyledModalAlert, { children: [_jsx(StyledTitle, { is_login: location.pathname.includes('login'), dangerouslySetInnerHTML: { __html: modal_alert_origin.data.title } }, void 0), modal_alert_origin.data.timeout ? null : modal_alert_origin.data.use_close_button === false ? null : (_jsx(CloseButton, __assign({ sx: {
                                top: '0.625rem',
                                right: '0.625rem',
                                width: '1.625rem',
                                height: '1.625rem',
                                svg: { width: '1.375rem', height: '1.375rem' },
                                backgroundColor: '#fff',
                            }, onClick: function () { return modal_alert_origin.closeModalAlertOrigin(); } }, { children: _jsx(IoCloseSharp, {}, void 0) }), void 0))] }, void 0) }), void 0) }), void 0) }, void 0));
}
export default ModalAlertOrigin;
