var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';
var CustomButton = styled(MuiButton)(function (props) { return ({
    boxShadow: 'none',
    fontSize: '1rem',
    lineHeight: 1.5,
    '&.regi': {
        minWidth: '8rem',
    },
    '&:hover': {
        boxShadow: 'none',
    },
    '&.disabled': {
        '&.MuiButton-contained': {
            backgroundColor: 'rgb(232, 232, 232) ',
            color: props.theme.palette.white.main,
        },
        '&.MuiButton-outlined': {
            borderColor: '#e8e8e8 ',
        },
    },
    '&.Mui-disabled': {
        '&.MuiButton-contained': {
            backgroundColor: 'rgb(232, 232, 232) ',
            color: props.theme.palette.white.main,
        },
        '&.MuiButton-outlined': {
            borderColor: '#e8e8e8 ',
        },
    },
}); });
function Button(props) {
    var variant = props.variant;
    var color = props.color;
    var children = props.children;
    var startIcon = props.startIcon;
    var endIcon = props.endIcon;
    var onClick = props.onClick;
    var disabled = props.disabled;
    var size = props.size;
    var sx = props.sx;
    var disableRipple = props.disableRipple;
    var class_name = props.className;
    var id = props.id;
    return (_jsx(CustomButton, __assign({ id: id, variant: variant, color: color, startIcon: startIcon, endIcon: endIcon, onClick: onClick, disabled: disabled, size: size, sx: sx, disableRipple: disableRipple, className: class_name, tabIndex: -1 }, { children: children }), void 0));
}
export default Button;
