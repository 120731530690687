var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createAction, createReducer } from 'typesafe-actions';
import { TEACHER_TYPES } from '../../utils/constants';
var initialState = {
    uid: 0,
    cid: 0,
    uclass: '0',
    uname: '',
    pass: false,
    alimtalkuse: 0,
    appreviewdone: 0,
    bepaid: 0,
    checkmoney: 0,
    memtype: '',
    postdate: '',
    smscnt: 0,
    smsok: 0,
    smsstop: '0',
    ubirth: '',
    uloginid: '',
    uphone: '',
    attendancesms: '',
    cname: '',
    phone: '',
    cell: '',
    attendance_alim_abs: 0,
    attendance_alim_att: 0,
    attendance_alim_title: 0,
    attendance_sms_abs: null,
    attendance_sms_att: null,
    attendance_sms_abs_msg: '',
    attendance_sms_att_msg: '',
    attendance_sms_title: '',
    account_alim_contents: '',
    account_alim_costday: 0,
    account_alim_direct: 0,
    account_alim_direct_msg: '',
    account_first_time: 0,
    account_second_date: 0,
    account_second_time: 0,
    account_second_notice: 0,
    use_attendance_msg: 0,
    avaouttime: 0,
    stdtime: 0,
    attendancemulti: 0,
    usehourformat: 0,
    president: '',
    logofile: '',
    logofile_type: '',
    profile: '',
    profile_type: '',
    smsphone: '',
    sms_err_status: null,
    smsstartday: 0,
    is_teacher: false,
    pwdate: '',
    free_end_date: '',
    current_list_num: 0,
    login: 0,
    autosms: 0,
    whenautosms: 0,
    autosmscontent: '',
    restime: '',
    account_sms: null,
    account_sms_msg: '',
    product_change_status: 0,
    pretest: 0,
    transfer_date: null,
    is_mobile: false,
    notice_lms: false,
    account_registration: 0,
    sp_pkid: null,
    cost: null,
    payment: null,
    notice_payment: false,
    // 수납관리 (사업자 정보)
    account_phone: '',
    account_number: '',
    business_number: '',
    ceo_name: '',
    customer: '',
    bank: '',
    // v2이면서 수납관리 사용자
    useaccount: 0,
    open_account: false,
    // 지출증빙 정보 미기입시 팝업 여부 (0: 안보이게, 1: 보이도록)
    notice_envidence: 0,
    // 수납 사용 가능여부
    open: 0,
    account_before: 0,
    account_before_date: 0,
    account_before_time: 0,
    mallkind: '',
    account_expire: 0,
    account_expire_time: 0,
    spam_notice: false,
    tableSort: {
        account_calculate_academy: {
            sortKey: null,
            sortValue: null,
        },
        account_calculate_user: {
            sortKey: null,
            sortValue: null,
        },
        account_check: {
            sortKey: null,
            sortValue: null,
        },
        account_manage_list: {
            sortKey: null,
            sortValue: null,
        },
        account_manage_create: {
            sortKey: null,
            sortValue: null,
        },
        account_statistics_period: {
            sortKey: null,
            sortValue: null,
        },
        account_statistics_daily: {
            sortKey: null,
            sortValue: null,
        },
        account_statistics_detail: {
            sortKey: null,
            sortValue: null,
        },
        attendance_covid_record: {
            sortKey: null,
            sortValue: null,
        },
        attendance_covid_unrecord: {
            sortKey: null,
            sortValue: null,
        },
        attendance_manager: {
            sortKey: null,
            sortValue: null,
        },
        attendance_status_record: {
            sortKey: null,
            sortValue: null,
        },
        attendance_status_unrecord: {
            sortKey: null,
            sortValue: null,
        },
        cash_charge: {
            sortKey: null,
            sortValue: null,
        },
        cash_used: {
            sortKey: null,
            sortValue: null,
        },
        setup_check: {
            sortKey: null,
            sortValue: null,
        },
        sms_detail: {
            sortKey: null,
            sortValue: null,
        },
        sms_send: {
            sortKey: null,
            sortValue: null,
        },
        sms_reservation: {
            sortKey: null,
            sortValue: null,
        },
        student_class: {
            sortKey: null,
            sortValue: null,
        },
        student_list: {
            sortKey: null,
            sortValue: null,
        },
        student_study: {
            sortKey: null,
            sortValue: null,
        },
        student_teacher: {
            sortKey: null,
            sortValue: null,
        },
        student_counsel: {
            sortKey: null,
            sortValue: null,
        },
        magazine_birthday: {
            sortKey: null,
            sortValue: null,
        },
    },
    // 실명인증 여부 (계정)
    // isCerti: 0,
    // 링크발송 본인인증 해지 요청 고객여부
    isRequestUser: false,
    // 화면 zoom 상태
    zoom_status: 0,
};
export var CLEAR_USER = 'UserReducder/CLEAR_USER';
export var SET_USER = 'UserReducder/SET_USER';
export var SET_FREE_END_USER = 'UserReducder/SET_FREE_END_USER';
export var SET_STOP_USER = 'UserReducder/SET_STOP_USER';
export var SET_USER_TABLE_SORT = 'UserReducer/SET_USER_TABLE_SORT';
export var clearUser = createAction(CLEAR_USER)();
export var setUser = createAction(SET_USER)();
export var setFreeEndUser = createAction(SET_FREE_END_USER)();
export var setStopUser = createAction(SET_STOP_USER)();
export var setUserTableSort = createAction(SET_USER_TABLE_SORT)();
export var actions = { setUser: setUser, clearUser: clearUser, setFreeEndUser: setFreeEndUser, setStopUser: setStopUser, setUserTableSort: setUserTableSort };
var UserReducder = createReducer(initialState, (_a = {},
    _a[CLEAR_USER] = function (state) {
        window.sessionStorage.removeItem('user');
        document.cookie = "a-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        return {
            uid: state.uid,
            cid: state.cid,
            uclass: '0',
            uname: '',
            pass: false,
            // 0: 사용 x, 1: 수납만 알림톡, 2: 출결만 알림톡, 3: 수납 + 출결 알림톡
            alimtalkuse: 0,
            appreviewdone: 0,
            bepaid: 0,
            checkmoney: 0,
            memtype: '',
            postdate: '',
            smscnt: 0,
            smsok: 0,
            smsstop: '0',
            ubirth: '',
            uloginid: '',
            uphone: '',
            attendancesms: '',
            cname: '',
            phone: '',
            cell: '',
            attendance_alim_abs: 0,
            attendance_alim_att: 0,
            attendance_alim_title: 0,
            attendance_sms_abs: null,
            attendance_sms_att: null,
            attendance_sms_abs_msg: '',
            attendance_sms_att_msg: '',
            attendance_sms_title: '',
            account_alim_contents: '',
            account_alim_costday: 0,
            account_alim_direct: 0,
            account_alim_direct_msg: '',
            account_first_time: 0,
            account_second_date: 0,
            account_second_time: 0,
            account_second_notice: 0,
            use_attendance_msg: 0,
            avaouttime: 0,
            stdtime: 0,
            attendancemulti: 0,
            usehourformat: 0,
            president: '',
            logofile: '',
            logofile_type: '',
            profile: '',
            profile_type: '',
            smsphone: '',
            sms_err_status: null,
            smsstartday: 0,
            is_teacher: false,
            pwdate: '',
            free_end_date: '',
            current_list_num: 0,
            login: 0,
            autosms: 0,
            whenautosms: 0,
            autosmscontent: '',
            restime: '',
            account_sms: null,
            account_sms_msg: '',
            product_change_status: 0,
            pretest: 0,
            transfer_date: null,
            is_mobile: false,
            notice_lms: false,
            account_registration: 0,
            sp_pkid: null,
            cost: null,
            payment: null,
            notice_payment: false,
            // 수납관리 (사업자 정보)
            account_phone: '',
            account_number: '',
            business_number: '',
            ceo_name: '',
            customer: '',
            bank: '',
            // v2이면서 수납관리 사용자
            useaccount: 0,
            open_account: false,
            // 지출증빙 정보 미기입시 팝업 여부 (0: 안보이게, 1: 보이도록)
            notice_envidence: 0,
            open: 0,
            account_before: 0,
            account_before_date: 0,
            account_before_time: 0,
            mallkind: '',
            account_expire: 0,
            account_expire_time: 0,
            spam_notice: false,
            tableSort: {
                account_calculate_academy: {
                    sortKey: null,
                    sortValue: null,
                },
                account_calculate_user: {
                    sortKey: null,
                    sortValue: null,
                },
                account_check: {
                    sortKey: null,
                    sortValue: null,
                },
                account_manage_list: {
                    sortKey: null,
                    sortValue: null,
                },
                account_manage_create: {
                    sortKey: null,
                    sortValue: null,
                },
                account_statistics_period: {
                    sortKey: null,
                    sortValue: null,
                },
                account_statistics_daily: {
                    sortKey: null,
                    sortValue: null,
                },
                account_statistics_detail: {
                    sortKey: null,
                    sortValue: null,
                },
                attendance_covid_record: {
                    sortKey: null,
                    sortValue: null,
                },
                attendance_covid_unrecord: {
                    sortKey: null,
                    sortValue: null,
                },
                attendance_manager: {
                    sortKey: null,
                    sortValue: null,
                },
                attendance_status_record: {
                    sortKey: null,
                    sortValue: null,
                },
                attendance_status_unrecord: {
                    sortKey: null,
                    sortValue: null,
                },
                cash_charge: {
                    sortKey: null,
                    sortValue: null,
                },
                cash_used: {
                    sortKey: null,
                    sortValue: null,
                },
                setup_check: {
                    sortKey: null,
                    sortValue: null,
                },
                sms_detail: {
                    sortKey: null,
                    sortValue: null,
                },
                sms_send: {
                    sortKey: null,
                    sortValue: null,
                },
                sms_reservation: {
                    sortKey: null,
                    sortValue: null,
                },
                student_class: {
                    sortKey: null,
                    sortValue: null,
                },
                student_list: {
                    sortKey: null,
                    sortValue: null,
                },
                student_study: {
                    sortKey: null,
                    sortValue: null,
                },
                student_teacher: {
                    sortKey: null,
                    sortValue: null,
                },
                student_counsel: {
                    sortKey: null,
                    sortValue: null,
                },
                magazine_birthday: {
                    sortKey: null,
                    sortValue: null,
                },
            },
            // 실명인증 여부 (계정)
            // isCerti: 0,
            // 링크발송 본인인증 해지 요청 고객여부
            isRequestUser: false,
            zoom_status: 0,
        };
    },
    _a[SET_USER] = function (state, action) {
        return __assign(__assign(__assign({}, state), action.payload), { is_teacher: action.payload.uclass ? TEACHER_TYPES.includes(action.payload.uclass) : state.is_teacher });
    },
    _a[SET_FREE_END_USER] = function (state) {
        return __assign(__assign({}, state), { smsstop: '1', smscnt: 0 });
    },
    _a[SET_STOP_USER] = function (state) { return (__assign(__assign({}, state), { smsstop: '1' })); },
    _a[SET_USER_TABLE_SORT] = function (state, action) {
        var _a;
        var new_state = __assign(__assign({}, state), { tableSort: __assign(__assign({}, state.tableSort), (_a = {}, _a[action.payload.columnKey] = {
                sortKey: action.payload.sortKey,
                sortValue: action.payload.sortValue,
            }, _a)) });
        if (window.sessionStorage.getItem('user')) {
            window.sessionStorage.setItem('user', JSON.stringify(new_state));
        }
        return new_state;
    },
    _a));
export default UserReducder;
