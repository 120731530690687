var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createAction, createReducer } from 'typesafe-actions';
var initialState = {
    student: {
        root: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        teacher: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        class: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        list: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        registration: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        study: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
    },
    attendance: {
        root: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        status: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        manager: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        sms: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        covid: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
    },
    sms: {
        root: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        send: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        detail: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        reservation: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
    },
    setup: {
        root: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        info: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        phone: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        receipt: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        check: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        service: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
    },
    cash: {
        root: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        charge: {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        'charge-detail': {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
        'use-detail': {
            link: 'https://youtu.be/KAtYihcwW-0 ',
            name: '출결버스 매뉴얼',
        },
    },
    account: {
        root: {
            link: 'https://youtu.be/zgX0hBz7Vpw',
            name: '영상 > 청구대상 관리',
        },
        check: {
            link: 'https://youtu.be/zgX0hBz7Vpw',
            name: '영상 > 청구대상 관리',
        },
        manage: {
            link: 'https://youtu.be/4s1Ow5xy98Q',
            name: '영상 > 청구내역 조회',
        },
        create: {
            link: 'https://youtu.be/4s1Ow5xy98Q',
            name: '영상 > 청구내역 조회',
        },
        statistics: {
            link: 'https://youtu.be/tMQxPpTJUjg',
            name: '영상 > 수납통계 조회',
        },
        calculate: {
            link: '',
            name: '',
        },
        sms: {
            link: '',
            name: '',
        },
    },
};
export var CLEAR_LINK = 'YoutubeReducer/CLEAR_LINK';
export var SET_LINK = 'YoutubeReducer/SET_LINK';
export var clearPath = createAction(CLEAR_LINK)();
export var setLink = createAction(SET_LINK)();
export var actions = { clearPath: clearPath, setLink: setLink };
var YoutubeReducer = createReducer(initialState, (_a = {},
    _a[CLEAR_LINK] = function (state) {
        return __assign({}, initialState);
    },
    _a[SET_LINK] = function (state, action) {
        var cur_state = __assign(__assign({}, state), action.payload);
        return cur_state;
    },
    _a));
export default YoutubeReducer;
