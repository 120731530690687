var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createAction, createReducer } from 'typesafe-actions';
var initialState = {
    path: '/',
    name: '',
    component: null,
    check_main_path: false,
    root_path: '',
    child_path: '',
};
export var CLEAR_PATH = 'RoutesReducer/CLEAR_PATH';
export var SET_PATH = 'RoutesReducer/SET_PATH';
export var clearPath = createAction(CLEAR_PATH)();
export var setPath = createAction(SET_PATH)();
export var actions = { setPath: setPath, clearPath: clearPath };
var RoutesReducer = createReducer(initialState, (_a = {},
    _a[CLEAR_PATH] = function () {
        return {
            path: '/',
            name: '',
            component: null,
            check_main_path: false,
            root_path: '',
            child_path: '',
        };
    },
    _a[SET_PATH] = function (state, action) {
        var path = action.payload.path.replace(/\/admin/g, '');
        var root_path = path.split("/")[1];
        var child_path = path.split("/")[2];
        return __assign(__assign({}, state), { path: path, root_path: root_path, child_path: child_path ? child_path : '', check_main_path: root_path != '' && root_path != 'magazine' ? false : true });
    },
    _a));
export default RoutesReducer;
