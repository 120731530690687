var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createAction, createReducer } from 'typesafe-actions';
var initialState = {
    t1: 0,
    t2: 0,
    t3: 0,
    t4: 0,
    t5: 0,
    status: 0,
};
export var SET_TUTORIAL = 'TutorialReducer/setTutorial';
export var setTutorial = createAction(SET_TUTORIAL)();
export var actions = { setTutorial: setTutorial };
var TutorialReducer = createReducer(initialState, (_a = {},
    _a[SET_TUTORIAL] = function (state, action) {
        var cur_state = __assign(__assign(__assign({}, state), action.payload), { status: 1 });
        console.log(cur_state, action.payload);
        return cur_state;
    },
    _a));
export default TutorialReducer;
