var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import ModalProvider from './provider/ModalProvider';
import TableProvider from './provider/TableProvider';
import { Box } from '@mui/material';
import theme from './theme';
import { CLEAR_USER, SET_USER } from './store/model/user';
import { BASE_URL } from './utils/constants';
import { Suspense, useEffect, useState } from 'react';
import Loading from './components/loading/Loading';
import LayoutAuth from './components/layout/LayoutAuth';
import socket from './Socket';
import channel from './Broadcast';
import { retryLazy } from './utils/tools';
import ModalPc from './components/modal/ModalPc';
var LayoutLogin = retryLazy(function () { return import('./components/layout/LayoutLogin'); });
var LayoutDefault = retryLazy(function () { return import('./components/layout/LayoutDefault'); });
// store 설정파일 로드
var store = configureStore();
function App() {
    var navigate = useNavigate();
    // const dispatch = useDispatch();
    var _a = useState(false), auth = _a[0], setAuth = _a[1];
    var _b = useSearchParams(), searchParams = _b[0], setSearchParams = _b[1];
    var _c = useState(false), attendanceMode = _c[0], setAttendanceMode = _c[1];
    useEffect(function () {
        try {
            window.Kakao.init('ddbcf21bee6865da2c724b33f4bfd6f8');
        }
        catch (err) {
            console.log(err);
        }
        var cur_mode = sessionStorage.getItem('mode');
        if (cur_mode && cur_mode == 'attendance') {
            setAttendanceMode(true);
        }
        else {
            var mode = searchParams.get('mode');
            if (mode && mode == 'attendance') {
                sessionStorage.setItem('mode', 'attendance');
                setAttendanceMode(true);
            }
        }
        function channelListener(event) {
            return __awaiter(this, void 0, void 0, function () {
                var data, user;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            data = event.data;
                            if (!(data.type == 'login')) return [3 /*break*/, 1];
                            // 이미 로그인 상태면 덮어씌우기
                            if (window.sessionStorage.getItem('user')) {
                                if (data.payload) {
                                    store.dispatch({
                                        type: SET_USER,
                                        payload: data.payload,
                                    });
                                    setTimeout(function () {
                                        window.sessionStorage.setItem('user', JSON.stringify(data.payload));
                                        navigate("".concat(BASE_URL, "/"));
                                    }, 0);
                                }
                            }
                            return [3 /*break*/, 4];
                        case 1:
                            if (!(data.type == 'logout')) return [3 /*break*/, 4];
                            if (!window.sessionStorage.getItem('user')) return [3 /*break*/, 3];
                            user = JSON.parse(window.sessionStorage.getItem('user'));
                            return [4 /*yield*/, socket.emit('logout', user)];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3:
                            store.dispatch({
                                type: CLEAR_USER,
                            });
                            setTimeout(function () {
                                window.sessionStorage.removeItem('user');
                                navigate("".concat(BASE_URL, "/login"), {
                                    state: {
                                        from_main: true,
                                    },
                                });
                            }, 0);
                            _a.label = 4;
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
        if (channel) {
            channel.addEventListener('message', channelListener);
        }
        return function () {
            if (channel) {
                channel.removeEventListener('message', channelListener);
            }
        };
    }, []);
    return (_jsx(Box, __assign({ sx: { overflowX: 'auto' }, id: 'root_wrapper' }, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(ModalProvider, { children: _jsx(TableProvider, { children: _jsx(Suspense, __assign({ fallback: _jsx(Loading, { isLogo: true }, void 0) }, { children: auth ? (attendanceMode ? (_jsx(_Fragment, { children: _jsx(ModalPc, { visible: true, onClose: function () { return false; } }, void 0) }, void 0)) : (_jsxs(Routes, { children: [_jsx(Route, { path: "".concat(BASE_URL, "/*"), element: _jsx(LayoutDefault, {}, void 0) }, void 0), _jsx(Route, { path: "".concat(BASE_URL, "/login"), element: _jsx(LayoutLogin, {}, void 0) }, void 0)] }, void 0))) : (_jsx(Routes, { children: _jsx(Route, { path: "".concat(BASE_URL, "/*"), element: _jsx(LayoutAuth, { onSubmit: function () { return setAuth(true); } }, void 0) }, void 0) }, void 0)) }), void 0) }, void 0) }, void 0) }), void 0) }), void 0) }), void 0));
}
export default App;
