var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createAction, createReducer } from 'typesafe-actions';
var initialState = {
    ModalYoutubeManual: {
        open: true,
    },
    ModalAccountPopup: {
        open: true,
    },
};
// Youtube modal
export var OPEN_MODAL_YOUTUBE = 'ModalYoutubeManualReducer/OPEN_MODAL';
export var CLOSE_MODAL_YOUTUBE = 'ModalYoutubeManualReducer/CLOSE_MODAL';
export var openModalYoutubeManual = createAction(OPEN_MODAL_YOUTUBE)();
export var closeModalYoutubeManual = createAction(CLOSE_MODAL_YOUTUBE)();
// Account modal
export var OPEN_MODAL_ACCOUNT = 'ModalAccountPopup/OPEN_MODAL';
export var CLOSE_MODAL_ACCOUNT = 'ModalAccountPopup/CLOSE_MODAL';
export var openModalAccount = createAction(OPEN_MODAL_ACCOUNT)();
export var closeModalAccount = createAction(CLOSE_MODAL_ACCOUNT)();
export var actions = { openModalYoutubeManual: openModalYoutubeManual, closeModalYoutubeManual: closeModalYoutubeManual, openModalAccount: openModalAccount, closeModalAccount: closeModalAccount };
var ModalYoutubeManualReducer = createReducer(initialState, (_a = {},
    _a[OPEN_MODAL_YOUTUBE] = function (state) {
        return __assign(__assign({}, state), { ModalYoutubeManual: {
                open: true,
            } });
    },
    _a[CLOSE_MODAL_YOUTUBE] = function (state) {
        return __assign(__assign({}, state), { ModalYoutubeManual: {
                open: false,
            } });
    },
    _a[OPEN_MODAL_ACCOUNT] = function (state) {
        return __assign(__assign({}, state), { ModalAccountPopup: {
                open: true,
            } });
    },
    _a[CLOSE_MODAL_ACCOUNT] = function (state) {
        return __assign(__assign({}, state), { ModalAccountPopup: {
                open: false,
            } });
    },
    _a));
export default ModalYoutubeManualReducer;
