var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ModalCustomDefault from '../../components/modal/ModalCustomDefault';
import InputNormal from '../input/InputNormal';
import ButtonGroup from '../../components/button/ButtonGroup';
import { styled, Box, Checkbox, Typography } from '@mui/material';
import { d_flex_center, dir_column, d_flex, d_flex_space_between } from '../../styles/common';
import { RiRefreshFill } from 'react-icons/ri';
import { AiFillLock, AiFillUnlock } from 'react-icons/ai';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { Fragment, useState, useEffect, useRef, useContext } from 'react';
import { fetchGetApi, fetchPostApi } from '../../utils/api';
import { setLocaleDateString, beforeUnLoadFunction } from '../../utils/tools';
import { useDispatch, useSelector } from 'react-redux';
import ModalCheckPw from './ModalCheckPW';
import ModalSelect from './ModalSelect';
import { ModalContext } from '../../provider/ModalProvider';
import { SET_FREE_END_USER, SET_USER } from '../../store/model/user';
import ok_mp3 from '../../../src/assets/audio/ok.mp3';
import fail_mp3 from '../../../src/assets/audio/fail.mp3';
import { SET_TUTORIAL } from '../../store/common/tutorial';
import Inko from 'inko';
var StyledModalPcContainer = styled(Box)(function (props) {
    var full_screen = props.fullScreen;
    var width = '48rem';
    var height = '42.5rem';
    if (full_screen == 'true') {
        width = '100vw';
        height = 'calc(100vh - 3.5rem)';
    }
    return {
        width: width,
        height: height,
        padding: '1.571rem 3.25rem 2.571rem',
        backgroundColor: props.theme.palette.white.main,
        position: 'relative',
    };
});
var StyledLogoWrapper = styled(Box)(function (props) { return (__assign(__assign({ height: '6.35rem', overflow: 'hidden', marginBottom: '1.5rem' }, d_flex_center), dir_column)); });
var StyledNowWrap = styled(Box)(function (props) { return (__assign({ fontSize: '1.725rem', height: '40%', color: '#5b5b5b', svg: {
        marginLeft: '0.125rem',
        fontSize: '2.2rem',
    } }, d_flex_center)); });
var StyledInputWrap = styled(Box)(function (props) { return ({
    height: '5.611rem',
    marginBottom: '0.85rem',
    backgroundColor: '#f7f7f7',
    borderRadius: '0.55rem',
    '& > div': {
        height: '100%',
        '& > div': {
            height: '100%',
            input: {
                textAlign: 'center',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                letterSpacing: '5px',
                '&:focus': {
                    fontSize: '3.5rem',
                    '&::placeholder': {
                        fontSize: '0rem',
                    },
                },
                '&::placeholder': {
                    fontSize: '1.5rem',
                    letterSpacing: '1px',
                    color: '#dcdcdc !important',
                    fontWeight: '400',
                    // lineHeight: '5.611rem !important',
                },
            },
        },
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#f1f1f1 !important',
        '&:focus-visible': {
            borderColor: '#f1f1f1',
        },
    },
    '&:hover': {
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#f1f1f1',
        },
    },
    '&.Mui-focused': {
        borderWidth: '1px',
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#f1f1f1',
        },
    },
}); });
var StyledListContainer = styled(Box)(function (props) { return ({
    height: 'calc(100% - 8.961rem)',
}); });
var StyledButtonWrapper = styled(Box)(function (props) { return ({
    height: '3.75rem',
}); });
var StyledListWrapper = styled(Box)(function (props) { return (__assign({ width: '50%', height: '100%', padding: '1rem 0 0 0', flexWrap: 'wrap', alignContent: 'flex-start', overflowY: 'auto' }, d_flex)); });
var ListSliderWrap = styled(Box)(function (props) { return ({
    width: '200%',
    height: '100%',
    transition: '0.4s ease-in-out',
    display: 'flex',
    '&.right': {
        marginLeft: '-100%',
    },
}); });
var StyledWrap = styled(Box)(function (props) {
    var direction_style = props.direction === 'left'
        ? {
            width: '33%',
            justifyContent: 'flex-end',
        }
        : props.direction === 'right'
            ? {
                width: '28%',
                justifyContent: 'flex-start',
            }
            : {
                width: '39%',
            };
    return __assign(__assign({ fontSize: '1.25rem', lineHeight: '2rem', height: '2rem', justifyContent: 'center', alignItems: 'center' }, d_flex), direction_style);
});
var StyledTopWrap = styled(Box)(function (props) { return ({
    width: '100%',
    height: 'auto',
    position: 'absolute',
    left: '0',
    top: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '0 1rem',
    minHeight: '2rem',
}); });
var StyledTopBox = styled(Box)(function (props) {
    var direction_style = props.direction === 'left'
        ? {
            fontWeight: '400',
            fontSize: '1.1rem',
            color: '#d6d6d6',
            svg: {
                fontSize: '1.625rem',
            },
            display: 'flex',
            alignItems: 'flex-end',
        }
        : {
            fontSize: '1.2rem',
            color: '#d6d6d6',
            svg: {
                fontSize: '1.125rem',
                fontWeight: 'bold',
                alignSelf: 'center',
            },
        };
    return __assign({ cursor: 'pointer', display: 'flex', alignItems: 'flex-end' }, direction_style);
});
var StyledSettingContainer = styled(Box)(function (props) { return ({
    width: '50%',
    height: '100%',
    overflow: 'hidden',
    '.mode-box': {
        width: '100%',
        height: '2.5rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
        '.MuiCheckbox-root': {
            padding: 0,
        },
    },
}); });
var SliderWrap = styled(Box)(function (props) { return ({
    width: 'calc(200% + 3.25rem)',
    height: 'calc(100% - 6.85rem)',
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '3.25rem',
    transition: '0.4s ease-in-out',
    '&.slide': {
        marginLeft: 'calc(-100% - 3.25rem)',
    },
}); });
var StyledSettingTitle = styled(Box)(function (props) { return (__assign({ width: '100%', height: '3.75rem', fontSize: '1.25rem', backgroundColor: '#f3f3f3', color: props.theme.palette.gray_1.main, borderTopLeftRadius: '0.75rem', borderTopRightRadius: '0.75rem' }, d_flex_center)); });
var StyledSettingBoxWrapper = styled(Box)(function (props) { return ({
    height: 'calc(100% - 3.75rem)',
    border: "1px solid ".concat(props.theme.palette.gray_3.main),
}); });
var StyledSettingBox = styled(Box)(function (props) { return (__assign(__assign({ height: '33.33%', padding: '1rem 2.5rem', borderBottom: "1px solid ".concat(props.theme.palette.gray_3.main) }, d_flex_space_between), { '&:last-of-type': {
        borderBottom: 'none',
    } })); });
var StyledSetting = styled(Box)(function (props) {
    var direction_style = props.direction === 'left'
        ? {
            width: 'calc(100% - 13rem)',
            marginRight: '1rem',
        }
        : {
            width: '12rem',
        };
    return __assign({}, direction_style);
});
var StyledTopSetting = styled(Box)(function (props) { return ({
    fontSize: '1.1rem',
    color: '#cdcdcd',
    cursor: 'pointer',
    fontWeight: '400',
    svg: {
        marginTop: '-2px',
        fontSize: '1.25rem',
        marginLeft: '0.25rem',
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    display: 'flex',
    alignItems: 'flex-end',
}); });
function ModalPc(props) {
    var _this = this;
    var dispatch = useDispatch();
    var user_info = useSelector(function (state) { return state.UserReducer; });
    var tutorial_info = useSelector(function (state) { return state.TutorialReducer; });
    var modal_alert = useContext(ModalContext).modal_alert;
    var cur_type = useRef('');
    var _a = useState(false), isSetting = _a[0], setIsSetting = _a[1];
    var _b = useState(''), attendanceNumber = _b[0], setAttendanceNumber = _b[1];
    var _c = useState(false), passwordModalVisible = _c[0], setPasswordModalVisible = _c[1];
    var _d = useState(''), currentTime = _d[0], setCurrentTime = _d[1];
    var _e = useState(null), apiInterval = _e[0], setApiInterval = _e[1];
    var _f = useState([]), startStudentList = _f[0], setStartStudentList = _f[1];
    var _g = useState([]), endStudentList = _g[0], setEndStudentList = _g[1];
    var _h = useState(false), updateLock = _h[0], setUpdateLock = _h[1];
    var _j = useState(false), attendanceStatus = _j[0], setAttendanceStatus = _j[1];
    var _k = useState({
        reload: true,
        lock: true,
        alarm: true,
    }), savedData = _k[0], setSavedData = _k[1];
    var _l = useState({
        visible: false,
        data: {
            modal_id: '',
            title: '',
            subtitle: '',
            type: '',
            contents: [],
        },
        currentInput: [],
    }), modalSelectContents = _l[0], setModalSelectContents = _l[1];
    var _m = useState([
        {
            title: '새로 고침',
            subtitle: 'PC 출결 내역을 실시간 새로고침(10초 간격)합니다.',
            value: true,
        },
        {
            title: '화면 잠금',
            subtitle: 'PC 출석체크 화면의 잠금 아이콘을 활성화 합니다.',
            value: true,
        },
        {
            title: '소리 설정',
            subtitle: '출결번호 입력 후 띵동~ 효과음을 제어합니다.',
            value: true,
        },
    ]), defaultSettings = _m[0], setDefaultSettings = _m[1];
    var _o = useState('left'), selectedButton = _o[0], setSelectedButton = _o[1];
    var visible = props.visible;
    var onCloseModal = props.onClose;
    var _p = useState(false), fullScreen = _p[0], setFullScreen = _p[1];
    function handleFocus() {
        var user = sessionStorage.getItem('user');
        if (!user) {
            modal_alert.openModalAlert('logout_using_modal_pc', undefined, function () {
                window.removeEventListener('beforeunload', beforeUnLoadFunction);
                sessionStorage.removeItem('mode');
                window.close();
            });
            return;
        }
    }
    function handleBlur() {
        var check_pc = localStorage.getItem('check_pc');
        if (check_pc) {
            var check_pc_json = JSON.parse(check_pc);
            if (check_pc_json['lock'] == true) {
                modal_alert.openModalAlert('using_modal_pc');
            }
        }
    }
    useEffect(function () {
        if (visible) {
            var cur_mode = sessionStorage.getItem('mode');
            if (cur_mode && cur_mode == 'attendance') {
                setFullScreen(true);
                setTimeout(function () {
                    var modal_el = document.getElementById('modal_default_container');
                    if (modal_el && modal_el.children[0]) {
                        modal_el.style.borderRadius = '0px';
                        modal_el.children[0].setAttribute('style', 'border-radius: 0px');
                    }
                    window.onfocus = handleFocus;
                    window.onblur = handleBlur;
                }, 0);
            }
            setSettings();
            getAttendanceList('starttime');
            getAttendanceList('endtime');
            setSelectedButton('left');
            if (tutorial_info.status == 1 && tutorial_info.t4 == 0) {
                dispatch({
                    type: SET_TUTORIAL,
                    payload: {
                        t4: 1,
                    },
                });
                fetchPostApi("/".concat(user_info.cid, "/user/").concat(user_info.uid, "/academy/tutorial"), { t4: 1 });
            }
        }
        else {
            if (apiInterval) {
                clearInterval(apiInterval);
            }
            setApiInterval(null);
            setIsSetting(false);
            setAttendanceNumber('');
        }
    }, [visible]);
    var setSettingMethod = function (index, value, updateLock) {
        if (index == 0) {
            if (apiInterval) {
                clearInterval(apiInterval);
            }
            if (value) {
                var tmp_api_interval = setInterval(function () {
                    getAttendanceList('starttime');
                    getAttendanceList('endtime');
                }, 10000);
                setApiInterval(tmp_api_interval);
            }
            else {
                setCurrentTime(setDateKor(new Date()));
                var tmp_api_interval = setInterval(function () {
                    var now = setDateKor(new Date());
                    setCurrentTime(now);
                }, 10000);
                setApiInterval(tmp_api_interval);
            }
        }
        else if (index == 1 && !value && defaultSettings[index].value) {
            setUpdateLock(true);
            setPasswordModalVisible(true);
        }
    };
    var setDateKor = function (now) {
        var date = setLocaleDateString(now, 'kor');
        var hours = now.getHours();
        var format = '';
        if (user_info.usehourformat == 1) {
            if (hours > 12) {
                hours = hours - 12;
                if (hours < 10) {
                    hours = '0' + hours;
                }
            }
            else {
                hours = hours < 10 ? '0' + hours : hours;
            }
            if (Number(new Date().getHours()) >= 12) {
                format = 'PM ';
            }
            else {
                hours = [0].includes(Number(hours)) ? 12 : hours;
                format = 'AM ';
            }
        }
        var minutes = now.getMinutes() < 10 ? "0".concat(now.getMinutes()) : now.getMinutes();
        return "".concat(date, " ").concat(format).concat(hours, ":").concat(minutes);
    };
    var setSettings = function () {
        var storage = window.localStorage;
        var storage_data = {
            reload: true,
            lock: true,
            alarm: true,
        };
        var check_data = storage.getItem('check_pc');
        if (!check_data) {
            storage_data = {
                reload: true,
                lock: true,
                alarm: true,
            };
            storage.setItem('check_pc', JSON.stringify(storage_data));
        }
        else {
            var saved_data = JSON.parse(check_data);
            storage_data = saved_data;
            var tmp_settings = __spreadArray([], defaultSettings, true);
            tmp_settings[0].value = saved_data.reload;
            tmp_settings[1].value = saved_data.lock;
            tmp_settings[2].value = saved_data.alarm;
            setDefaultSettings(__spreadArray([], tmp_settings, true));
        }
        setSettingMethod(0, storage_data.reload);
        setSavedData(storage_data);
    };
    var onClickNaviButton = function () {
        setIsSetting(!isSetting);
        setAttendanceNumber('');
    };
    var getAttendanceList = function (type) { return __awaiter(_this, void 0, void 0, function () {
        var get_res, list, tmp_list, time_type_1, cur_time, splited_time, time, hours, minutes, cur_date;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    cur_type.current = type;
                    return [4 /*yield*/, fetchGetApi("/".concat(user_info.cid, "/user/").concat(user_info.uid, "/attendance/list?date=").concat(setLocaleDateString(new Date(), '-'), "&type=").concat(cur_type.current, "&format=").concat(user_info.usehourformat == 0 ? 'HH' : 'h', "&stdtime=").concat(user_info.stdtime))];
                case 1:
                    get_res = _a.sent();
                    list = get_res.message.result;
                    if (list == 'null') {
                        if (type == 'starttime') {
                            setStartStudentList([]);
                        }
                        else {
                            setEndStudentList([]);
                        }
                    }
                    else {
                        tmp_list = __spreadArray([], list, true);
                        if (user_info.usehourformat) {
                            time_type_1 = 'starttime';
                            if (type == 'endtime') {
                                time_type_1 = 'endtime';
                            }
                            tmp_list = __spreadArray([], tmp_list, true).map(function (item) {
                                var cur_time = new Date("1001-01-01 ".concat(item.time, ":00"));
                                var hour = cur_time.getHours() < 10 ? "0".concat(cur_time.getHours()) : cur_time.getHours();
                                hour = [0].includes(Number(hour)) ? 12 : hour;
                                var minutes = cur_time.getMinutes() < 10 ? "0".concat(cur_time.getMinutes()) : cur_time.getMinutes();
                                return __assign(__assign({}, item), { time: new Date("1001-01-01 ".concat(item[time_type_1])).getHours() >= 12
                                        ? "PM ".concat(hour, ":").concat(minutes)
                                        : "AM ".concat(hour, ":").concat(minutes) });
                            });
                        }
                        if (type == 'starttime') {
                            setStartStudentList(__spreadArray([], tmp_list, true));
                        }
                        else {
                            setEndStudentList(__spreadArray([], tmp_list, true));
                        }
                    }
                    cur_time = get_res.message.time;
                    if (user_info.usehourformat == 1) {
                        splited_time = "".concat(cur_time).split(' ');
                        time = splited_time[splited_time.length - 1];
                        hours = Number(time.split(':')[0]) < 10 ? "0".concat(time.split(':')[0]) : time.split(':')[0];
                        minutes = time.split(':')[1];
                        time = "".concat(hours, ":").concat(minutes);
                        splited_time[splited_time.length - 1] = time;
                        cur_date = "".concat(cur_time)
                            .split('일')[0]
                            .replaceAll(' ', '')
                            .replace(/[^0-9]/g, '-');
                        setCurrentTime(setDateKor(new Date("".concat(cur_date, " ").concat(time, ":00"))));
                    }
                    else {
                        setCurrentTime(cur_time);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var checkPassword = function (password) { return __awaiter(_this, void 0, void 0, function () {
        var inko, data, check_res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (password.length == 0) {
                        modal_alert.openModalAlert('write_password');
                        return [2 /*return*/];
                    }
                    inko = new Inko();
                    data = {
                        id: user_info.uloginid,
                        pwd: password,
                        pwdKr: inko.en2ko(password),
                        pwdEn: inko.ko2en(password),
                    };
                    return [4 /*yield*/, fetchPostApi("/".concat(user_info.cid, "/user/").concat(user_info.uid, "/check/password"), data)];
                case 1:
                    check_res = _a.sent();
                    if (check_res.pass) {
                        if (!updateLock) {
                            onCloseModal();
                        }
                        else {
                            onClickSettingButton('right', undefined, 1);
                        }
                        setPasswordModalVisible(false);
                    }
                    else {
                        modal_alert.openModalAlert('check_password');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var clearModalSelect = function () {
        setModalSelectContents({
            visible: false,
            data: {
                modal_id: '',
                title: '',
                subtitle: '',
                type: '',
                contents: [],
            },
            currentInput: [],
        });
    };
    var handleInput = function (e) {
        var check = /^[0-9]+$/; // 숫자만 입력받은 후에
        if (e.target.value.length >= 0 && e.target.value.length <= 6) {
            if (check.test(e.target.value) || e.target.value === '') {
                var new_value = e.target.value.trim() === '' ? '' : e.target.value;
                setAttendanceNumber(new_value);
            }
        }
    };
    var handleKeyDown = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            if (e.key === 'Enter' && attendanceNumber && !attendanceStatus) {
                handleAttendanceStatus('start').then(function () { return __awaiter(_this, void 0, void 0, function () {
                    var multi_check_res, classes, alert_type;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, fetchPostApi("/".concat(user_info.cid, "/user/").concat(user_info.uid, "/class/multi"), {
                                    memno: attendanceNumber,
                                })];
                            case 1:
                                multi_check_res = _a.sent();
                                classes = multi_check_res.message;
                                if (typeof classes == 'string') {
                                    alert_type = '';
                                    if (classes == 'no student') {
                                        alert_type = 'check_memno';
                                    }
                                    else {
                                        alert_type = 'check_rooms';
                                    }
                                    modal_alert.openModalAlert(alert_type);
                                    setAttendanceNumber('');
                                    setAttendanceStatus(false);
                                }
                                else if (classes.length == 1) {
                                    sendAttendance(classes[0].rid, classes[0].rname);
                                }
                                else {
                                    setModalSelectContents({
                                        visible: true,
                                        data: __assign(__assign({}, modalSelectContents.data), { title: '클래스 선택', contents: __spreadArray([], classes.map(function (item) { return ({ name: item.rname, id: item.rid }); }), true), type: 'radio', modal_id: 'multi_class_modal' }),
                                        currentInput: [],
                                    });
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
            }
            return [2 /*return*/];
        });
    }); };
    var handleAttendanceStatus = function (type) {
        return new Promise(function (resolve, reject) {
            if (type == 'start') {
                setAttendanceStatus(true);
            }
            else {
                setAttendanceStatus(false);
            }
            resolve(true);
        });
    };
    var sendAttendance = function (rid, rname) { return __awaiter(_this, void 0, void 0, function () {
        var data, send_res, audio_type, cur_button, new_academy_info, smscnt, attendancesms, alert_type, audio;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = {
                        type: null,
                        memno: attendanceNumber,
                        alimtalkuse: user_info.alimtalkuse,
                        smsok: user_info.smsok,
                        rid: rid,
                        rname: rname,
                    };
                    return [4 /*yield*/, fetchPostApi("/".concat(user_info.cid, "/user/").concat(user_info.uid, "/attendance/send/memno"), data)];
                case 1:
                    send_res = _a.sent();
                    audio_type = ok_mp3;
                    if (!send_res.pass) return [3 /*break*/, 4];
                    // 체험하기 전용
                    // 체험하기 기간 끝나면 지우도록
                    if (user_info.pretest == 1) {
                        cur_button = '';
                        if (selectedButton == 'right') {
                            cur_button = 'left';
                        }
                        else {
                            cur_button = 'right';
                        }
                        setSelectedButton(cur_button);
                        setAttendanceNumber('');
                        setAttendanceStatus(false);
                        return [2 /*return*/];
                    }
                    if (send_res.is_update) {
                        setSelectedButton('right');
                    }
                    else {
                        setSelectedButton('left');
                    }
                    getAttendanceList('starttime');
                    getAttendanceList('endtime');
                    if (send_res.attendancecount && send_res.maxattendancecount) {
                        modal_alert.openModalAlert('check_attendance_count', "".concat(send_res.maxattendancecount, "\uD68C \uC911 \uC794\uC5EC ").concat(send_res.attendancecount, "\uAC74"));
                    }
                    if (!(user_info.attendancesms == '1')) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetchGetApi("/".concat(user_info.cid, "/user/").concat(user_info.uid, "/academy/info"))];
                case 2:
                    new_academy_info = _a.sent();
                    smscnt = new_academy_info.message.smscnt;
                    attendancesms = new_academy_info.message.attendancesms;
                    dispatch({
                        type: SET_USER,
                        payload: {
                            smscnt: smscnt,
                            attendancesms: attendancesms,
                        },
                    });
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    alert_type = '';
                    if (send_res.message == 'already attendance') {
                        alert_type = 'already_attendance';
                    }
                    else if (send_res.message == 'unabled time') {
                        alert_type = 'unabled_time';
                    }
                    else if (send_res.message == 'no student') {
                        alert_type = 'check_memno';
                    }
                    else {
                        alert_type = 'free_use_all_cnt';
                    }
                    if (alert_type == 'free_use_all_cnt')
                        onCloseModal();
                    modal_alert.openModalAlert(alert_type, undefined, alert_type == 'free_use_all_cnt'
                        ? function () {
                            var type = SET_FREE_END_USER;
                            dispatch({
                                type: type,
                            });
                        }
                        : undefined);
                    audio_type = fail_mp3;
                    _a.label = 5;
                case 5:
                    if (savedData.alarm) {
                        audio = new Audio(audio_type);
                        audio.play();
                    }
                    setAttendanceNumber('');
                    setAttendanceStatus(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onClickListButton = function (direction) {
        setSelectedButton(direction);
    };
    var onClickSettingButton = function (direction, default_type, index) {
        if (index || index === 0) {
            var value = direction == 'left' ? true : false;
            var tmp_settings = __spreadArray([], defaultSettings, true);
            var tmp_storage_data = __assign({}, savedData);
            if (index == 0) {
                tmp_settings[index].value = value;
                tmp_storage_data.reload = value;
            }
            else if (index == 1) {
                if (value) {
                    tmp_settings[index].value = value;
                    tmp_storage_data.lock = value;
                    setUpdateLock(false);
                }
                else {
                    if (updateLock) {
                        tmp_settings[index].value = value;
                        tmp_storage_data.lock = value;
                        setUpdateLock(false);
                    }
                }
            }
            else if (index == 2) {
                tmp_settings[index].value = value;
                tmp_storage_data.alarm = value;
            }
            setDefaultSettings(__spreadArray([], defaultSettings, true));
            setSettingMethod(index, value);
            setSavedData(tmp_storage_data);
            window.localStorage.setItem('check_pc', JSON.stringify(tmp_storage_data));
        }
    };
    var checkClose = function () {
        if (savedData.lock && user_info.pretest == 0) {
            setPasswordModalVisible(true);
        }
        else {
            onCloseModal();
        }
    };
    var checkClassId = function (value) {
        sendAttendance(Number(value[0].id), value[0].name);
    };
    var onCancelSelectModal = function () {
        setAttendanceStatus(false);
    };
    var openFullScrren = function () {
        if (!fullScreen) {
            window.open('/?mode=attendance', 'attendanceonline', "fullscreen=yes, resizable=no, scrollbars=no, width=".concat(screen.width, ", height=").concat(screen.height));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalCustomDefault, __assign({ title: 'PC 출석체크', visible: visible, onClose: checkClose, useBlur: false, useHeaderButton: !fullScreen }, { children: _jsxs(StyledModalPcContainer, __assign({ fullScreen: "".concat(fullScreen) }, { children: [isSetting ? (_jsx(StyledTopWrap, { children: _jsxs(StyledTopSetting, __assign({ onClick: onClickNaviButton }, { children: [_jsx(BsChevronLeft, {}, void 0), " \uCD9C\uC11D"] }), void 0) }, void 0)) : (_jsxs(StyledTopWrap, { children: [_jsxs(StyledTopBox, __assign({ direction: 'left' }, { children: [savedData.lock ? _jsx(AiFillLock, {}, void 0) : _jsx(AiFillUnlock, {}, void 0), _jsx(Box, __assign({ component: 'span' }, { children: "\uC7A0\uAE08" }), void 0)] }), void 0), _jsxs(StyledTopBox, __assign({ direction: 'right', onClick: onClickNaviButton }, { children: ["Settings ", _jsx(BsChevronRight, {}, void 0)] }), void 0)] }, void 0)), _jsxs(StyledLogoWrapper, { children: [_jsxs(Box, __assign({ sx: __assign(__assign({ fontSize: '2rem', height: '60%' }, d_flex_center), { gap: '0.5rem', fontFamily: 'OSeongandHanEum, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important', fontWeight: '600', letterSpacing: '-1px' }) }, { children: [_jsx(Box, { component: 'img', src: 'https://kr.object.ncloudstorage.com/cdn-irontrain/common/images/checkbus_V2_logo.png', sx: { maxHeight: '90%' } }, void 0), "\uC2A4\uB9C8\uD2B8 \uCD9C\uACB0"] }), void 0), _jsxs(StyledNowWrap, { children: [currentTime, _jsx(RiRefreshFill, { color: '#bcbcbc', style: { cursor: 'pointer', marginLeft: '0.5rem' }, onClick: function () {
                                                getAttendanceList('starttime');
                                                getAttendanceList('endtime');
                                            } }, void 0)] }, void 0)] }, void 0), _jsxs(SliderWrap, __assign({ className: isSetting ? 'slide' : '' }, { children: [_jsxs(StyledSettingContainer, { children: [_jsx(StyledSettingTitle, { children: "\uC124\uC815" }, void 0), _jsx(StyledSettingBoxWrapper, { children: defaultSettings.map(function (item, idx) { return (_jsxs(StyledSettingBox, { children: [_jsxs(StyledSetting, __assign({ direction: 'left' }, { children: [_jsx(Box, __assign({ sx: { fontWeight: 'bold', fontSize: '1.125rem' } }, { children: item.title }), void 0), _jsx(Box, __assign({ sx: { color: '#bcbcbc', fontSize: '1.125rem' } }, { children: item.subtitle }), void 0)] }), void 0), _jsx(StyledSetting, __assign({ direction: 'right' }, { children: _jsx(ButtonGroup, { index: idx, height: '2.25rem', defaultType: item.value ? 'left' : 'right', type: 'pc', onClick: onClickSettingButton, sx: { width: 0.5, minWidth: 0, padding: 0 } }, void 0) }), void 0)] }, "setting_box_".concat(idx))); }) }, void 0)] }, void 0), _jsxs(StyledSettingContainer, { children: [_jsxs(Box, __assign({ className: 'mode-box' }, { children: [_jsx(Checkbox, { checked: fullScreen, onClick: openFullScrren, disabled: fullScreen }, void 0), _jsx(Typography, __assign({ component: 'span', onClick: openFullScrren, sx: { cursor: fullScreen ? 'inherit' : 'pointer' } }, { children: "\uC804\uCCB4\uBAA8\uB4DC" }), void 0)] }), void 0), _jsx(StyledInputWrap, __assign({ sx: { input: { fontSize: attendanceNumber.length > 0 ? '3.5rem !important' : '1.5rem' } } }, { children: _jsx(InputNormal, { type: 'text', value: attendanceNumber, onChange: handleInput, onKeyDown: handleKeyDown, autoFocus: true, placeholder: '출결번호' }, void 0) }), void 0), _jsxs(StyledListContainer, { children: [_jsx(StyledButtonWrapper, { children: _jsx(ButtonGroup, { height: '100%', defaultType: selectedButton, type: 'pc2', onClick: onClickListButton, sx: {
                                                            '&.MuiButtonGroup-grouped.MuiButtonGroup-groupedOutlinedPrimary': {
                                                                width: 0.5,
                                                                minWidth: 0,
                                                                height: 1,
                                                                fontSize: '1.25rem',
                                                                lineHeight: '1.25rem',
                                                                borderTopLeftRadius: '0.75rem',
                                                                borderTopRightRadius: '0.75rem',
                                                                borderBottomLeftRadius: '0',
                                                                borderBottomRightRadius: '0',
                                                                textAlign: 'center',
                                                            },
                                                            '&.MuiButton-outlined': {
                                                                backgroundColor: '#f3f3f3 !important',
                                                                borderColor: '#f3f3f3 !important',
                                                            },
                                                        } }, void 0) }, void 0), _jsx(Box, __assign({ sx: {
                                                        width: '100%',
                                                        height: 'calc(100% - 3.75rem)',
                                                        border: "1px solid",
                                                        borderColor: '#f1f1f1',
                                                        borderTop: 'none',
                                                    } }, { children: _jsxs(ListSliderWrap, __assign({ className: selectedButton }, { children: [_jsx(StyledListWrapper, { children: startStudentList.length > 0 ? (startStudentList.map(function (item, index) { return (_jsxs(Fragment, { children: [_jsxs(StyledWrap, __assign({ direction: 'left' }, { children: [_jsx(Box, __assign({ sx: { minWidth: '5%', marginRight: '0.25rem' } }, { children: "".concat(item.no, ".") }), void 0), _jsx(Box, __assign({ sx: {
                                                                                        width: '55%',
                                                                                        whiteSpace: 'nowrap',
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        // textAlign: 'center',
                                                                                    } }, { children: "".concat(item.rname) }), void 0)] }), void 0), _jsx(StyledWrap, __assign({ direction: 'center' }, { children: _jsx(Box, __assign({ sx: {
                                                                                    width: '51.5%',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    textAlign: ' center',
                                                                                    marginRight: '2.25rem',
                                                                                } }, { children: "".concat(item.uname) }), void 0) }), void 0), _jsx(StyledWrap, __assign({ direction: 'right' }, { children: _jsx(Box, __assign({ sx: { minWidth: '30%' } }, { children: item.time }), void 0) }), void 0)] }, "pc_attendance_list_".concat(index + 1))); })) : (_jsx(Fragment, { children: _jsx(StyledWrap, __assign({ sx: { width: '100%', height: '100%' }, direction: 'center' }, { children: _jsx(Box, __assign({ sx: { minWidth: '30%', textAlign: 'center', color: '#c8c8c8' } }, { children: "\uB4F1\uC6D0 \uB0B4\uC5ED\uC774 \uC5C6\uC2B5\uB2C8\uB2E4." }), void 0) }), void 0) }, void 0)) }, void 0), _jsx(StyledListWrapper, { children: endStudentList.length > 0 ? (endStudentList.map(function (item, index) { return (_jsxs(Fragment, { children: [_jsxs(StyledWrap, __assign({ direction: 'left' }, { children: [_jsx(Box, __assign({ sx: { minWidth: '5%', marginRight: '0.25rem' } }, { children: "".concat(item.no, ".") }), void 0), _jsx(Box, __assign({ sx: {
                                                                                        width: '55%',
                                                                                        whiteSpace: 'nowrap',
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        // textAlign: 'center',
                                                                                    } }, { children: "".concat(item.rname) }), void 0)] }), void 0), _jsx(StyledWrap, __assign({ direction: 'center' }, { children: _jsx(Box, __assign({ sx: {
                                                                                    width: '51.5%',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    textAlign: ' center',
                                                                                    marginRight: '2.25rem',
                                                                                } }, { children: "".concat(item.uname) }), void 0) }), void 0), _jsx(StyledWrap, __assign({ direction: 'right' }, { children: _jsx(Box, __assign({ sx: { minWidth: '30%' } }, { children: item.time }), void 0) }), void 0)] }, "pc_attendance_list_".concat(index + 1))); })) : (_jsx(Fragment, { children: _jsx(StyledWrap, __assign({ sx: { width: '100%', height: '100%' }, direction: 'center' }, { children: _jsx(Box, __assign({ sx: { minWidth: '30%', textAlign: 'center', color: '#c8c8c8' } }, { children: "\uD558\uC6D0 \uB0B4\uC5ED\uC774 \uC5C6\uC2B5\uB2C8\uB2E4." }), void 0) }), void 0) }, void 0)) }, void 0)] }), void 0) }), void 0)] }, void 0)] }, void 0)] }), void 0)] }), void 0) }), void 0), _jsx(ModalCheckPw, { visible: passwordModalVisible, onClose: function () {
                    setPasswordModalVisible(false);
                    setUpdateLock(false);
                }, onSubmit: checkPassword }, void 0), _jsx(ModalSelect, { visible: modalSelectContents.visible, data: modalSelectContents.data, currentInput: modalSelectContents.currentInput, onCancel: onCancelSelectModal, onClick: function () { return clearModalSelect(); }, onChange: function (value) { return checkClassId(value); } }, void 0)] }, void 0));
}
export default ModalPc;
