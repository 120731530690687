var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { d_flex_center } from '../../styles/common';
import { styled, CircularProgress, circularProgressClasses, Box } from '@mui/material';
var StyledLoadingBox = styled(Box)(function (props) {
    var alert_style = props.is_alert || props.is_full_size
        ? {
            height: '100vh',
            width: '100vw',
            zIndex: '999',
            backgroundColor: 'rgba(33, 33, 33, 0.46)',
            top: '0',
            left: '0',
        }
        : {};
    var calendar_style = props.is_calendar ? { height: 'auto', marginTop: '2rem' } : {};
    var magazine_style = props.is_magazine ? { height: 'calc(100% - 6.315% - 1.5rem)' } : {};
    var abs_style = props.is_abs ? { zIndex: '90', width: 'calc(100% - 36px)', backgroundColor: '#fff' } : {};
    var attendance_style = props.is_attendance
        ? {
            zIndex: '90',
            width: 'calc(100% )',
            backgroundColor: 'rgba(255, 255, 255, 0.46)',
            height: 'calc(100%)',
            top: '0',
        }
        : {};
    return __assign(__assign(__assign(__assign(__assign(__assign({ width: '100%', height: props.is_councel ? '100%' : 'calc(100vh)', position: props.is_abs || props.is_alert || props.is_attendance ? 'absolute' : props.is_full_size ? 'fixed' : 'relative' }, d_flex_center), alert_style), calendar_style), magazine_style), abs_style), attendance_style);
});
var StyledLoadingLabel = styled(Box)(function (props) { return ({
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: props.is_alert || props.is_full_size ? '#fff !important' : props.theme.palette.gray_1.main,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    position: 'absolute',
}); });
var StyledCircularProgressBottom = styled(CircularProgress)(function (props) { return ({
    color: props.theme.palette.grey[props.theme.palette.mode === 'light' ? 300 : 800],
}); });
var StyledCircularProgressTop = styled(CircularProgress)(function (props) { return ({
    // color: props.theme.palette.mode === 'light' ? props.theme.palette.green.main : props.theme.palette.yellow_green.main,
    color: '#f23322',
    animationDuration: '650ms',
    position: 'absolute',
}); });
var StyledLoadingLogoWrap = styled(Box)(__assign({ width: '100vw', height: '100vh', position: 'absolute', zIndex: '99999' }, d_flex_center));
var StyledLogo = styled('img')({
    width: '15%',
    height: '15%',
});
function Loading(props) {
    var _a;
    var is_logo = props.isLogo;
    var is_alert = props.isAlert;
    var is_calendar = props.isCalendar;
    var is_magazine = props.isMagazine;
    var is_abs = props.isAbs;
    var is_councel = props.isCouncel;
    var is_full_size = props.isFullSize;
    var is_attendance = props.isAttendance;
    var sx = props.sx;
    return (_jsx(_Fragment, { children: is_logo ? (_jsx(StyledLoadingLogoWrap, { children: _jsx(StyledLogo, { src: 'https://kr.object.ncloudstorage.com/cdn-irontrain/common/images/checkbus_V2_textE.svg' }, void 0) }, void 0)) : (_jsxs(StyledLoadingBox, __assign({ is_alert: is_alert, is_calendar: is_calendar, is_magazine: is_magazine, is_councel: is_councel, is_abs: is_abs, is_full_size: is_full_size, is_attendance: is_attendance, id: 'Loading-box', sx: sx }, { children: [_jsx(StyledCircularProgressBottom, { variant: 'determinate', size: '10rem', thickness: 4, value: 100 }, void 0), _jsx(StyledCircularProgressTop, { variant: 'indeterminate', disableShrink: true, sx: (_a = {},
                        _a["& .".concat(circularProgressClasses.circle)] = {
                            strokeLinecap: 'round',
                        },
                        _a), size: '10rem', thickness: 4 }, void 0), _jsx(StyledLoadingLabel, __assign({ is_alert: is_alert, is_full_size: is_full_size, is_attendance: is_attendance }, { children: "Loading" }), void 0)] }), void 0)) }, void 0));
}
export default Loading;
