var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { CLEAR_USER, SET_USER } from '../../store/model/user';
import { fetchPostApi, getCookie } from '../../utils/api';
import { BASE_URL } from '../../utils/constants';
import { setLoginUserInfo } from '../../utils/tools';
import Loading from '../loading/Loading';
import channel from '../../Broadcast';
var LayoutAuth = function (props) {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var onSubmit = props.onSubmit;
    useEffect(function () {
        if (window.location.pathname.replace('/admin', '') == '/auth') {
            window.addEventListener('message', parentReceiveMessage);
        }
        else {
            authUser();
        }
        return function () {
            window.addEventListener('message', parentReceiveMessage);
        };
    }, []);
    function parentReceiveMessage(event) {
        // if (event.origin !== 'http://localhost:8080' && event.message !== 'iframe is ready') {
        //   return;
        // }
        if ([
            'http://49.236.145.134',
            'https://checkbus.co.kr',
            'http://checkbus.co.kr',
            'http://www.checkbus.co.kr',
            'https://www.checkbus.co.kr',
            'http://checkbus.irontrain.co.kr',
            'https://checkbus.irontrain.co.kr',
            'http://admin.checkbus.co.kr',
            'https://admin.checkbus.co.kr',
        ].includes(event.origin)) {
            var _a = event.data, v2_id = _a.v2_id, v2_pwd = _a.v2_pwd;
            if (v2_id && v2_pwd) {
                window.opener.focus();
                window.opener.close();
                window.opener.postMessage('opened', '*');
                window.addEventListener('message', parentReceiveMessage);
                autoLogin(v2_id, v2_pwd, location.pathname.replace('/admin', ''));
            }
        }
    }
    var autoLogin = function (id, pw, path) { return __awaiter(void 0, void 0, void 0, function () {
        var cur_id, cur_pw, query, phone, phone_num, login_res, payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    document.cookie = "a-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                    if (channel) {
                        channel.postMessage({
                            type: 'logout',
                        });
                    }
                    dispatch({
                        type: CLEAR_USER,
                    });
                    sessionStorage.removeItem('user');
                    cur_id = id;
                    cur_pw = pw;
                    if (path == '/pretest') {
                        cur_id = 'pretest';
                        cur_pw = '1234';
                        query = location.href.replace('/admin', '').split('?')[1]
                            ? location.href.replace('/admin', '').split('?')[1].split('&')
                            : [];
                        phone = query.find(function (item) { return item.includes('phone'); });
                        if (phone) {
                            phone_num = phone.replace(/phone=/gi, '');
                            if (phone_num) {
                                localStorage.setItem('pretest_phone', "".concat(phone_num));
                            }
                        }
                    }
                    return [4 /*yield*/, fetchPostApi('/login', {
                            id: cur_id,
                            pw: cur_pw,
                        })];
                case 1:
                    login_res = _a.sent();
                    if (login_res.pass) {
                        payload = setLoginUserInfo(login_res.message);
                        sessionStorage.setItem('user', JSON.stringify(payload));
                        dispatch({
                            type: SET_USER,
                            payload: payload,
                        });
                        if (['/pretest', '/auth'].includes(path)) {
                            navigate("".concat(BASE_URL, "/"));
                        }
                    }
                    else {
                        document.cookie = "a-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                        if (channel) {
                            channel.postMessage({
                                type: 'logout',
                            });
                        }
                    }
                    onSubmit();
                    return [2 /*return*/];
            }
        });
    }); };
    var authUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var auto, query, auto_login, a_token, login_res, payload, pathname;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!['/pretest', '/auth'].includes(location.pathname.replace('/admin', ''))) return [3 /*break*/, 1];
                    autoLogin('', '', location.pathname.replace('/admin', ''));
                    return [3 /*break*/, 5];
                case 1:
                    auto = true;
                    if (location.href.replace('/admin', '').includes('login') &&
                        location.href.replace('/admin', '').includes('auto')) {
                        query = location.href.replace('/admin', '').split('?')[1].split('&');
                        auto_login = query.find(function (item) { return item.includes('auto='); });
                        if (auto_login && auto_login.includes('false')) {
                            document.cookie = "a-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                            if (channel) {
                                channel.postMessage({
                                    type: 'logout',
                                });
                            }
                            sessionStorage.removeItem('user');
                            navigate("".concat(BASE_URL, "/login"));
                            auto = false;
                        }
                    }
                    a_token = getCookie('a-token');
                    if (!(a_token && auto)) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetchPostApi('/login/remain', {})];
                case 2:
                    login_res = _a.sent();
                    if (login_res.pass) {
                        payload = setLoginUserInfo(login_res.message);
                        sessionStorage.setItem('user', JSON.stringify(payload));
                        dispatch({
                            type: SET_USER,
                            payload: payload,
                        });
                        pathname = location.pathname.replace('/admin', '');
                        if (pathname.includes('login')) {
                            navigate("".concat(BASE_URL, "/"));
                        }
                    }
                    else {
                        document.cookie = "a-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                        if (channel) {
                            channel.postMessage({
                                type: 'logout',
                            });
                        }
                    }
                    return [3 /*break*/, 4];
                case 3:
                    console.log(window.location);
                    _a.label = 4;
                case 4:
                    onSubmit();
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return _jsx(Loading, { isLogo: true }, void 0);
};
export default LayoutAuth;
