import { io } from 'socket.io-client';
var url = 'https://checktest.irontrain.co.kr'; // dev server
if (process.env.NODE_ENV == 'production') {
    url = 'https://api.checkbus.co.kr';
}
var socket = io(url, {
    transports: ['websocket'],
    autoConnect: false,
});
socket.on('connect', function () {
    console.log('socket id : ', socket.id);
    if (sessionStorage.getItem('user')) {
        var user_info = JSON.parse(sessionStorage.getItem('user'));
        if (socket.id && user_info) {
            socket.emit('joinRoom', user_info);
        }
    }
});
socket.on('joinRoomRes', function (res) {
    console.log(res);
});
socket.on('disconnect', function () {
    console.log('socket id : ', socket.id); // undefined
});
export default socket;
