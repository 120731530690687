import { combineReducers } from 'redux';
//Model
import UserReducer from './model/user';
import VersionReducer from './model/version';
import ModalYoutubeManualReducer from './model/modalPopup';
// Common
import RoutesReducer from './common/routes';
import SearchbarReducer from './common/searchbar';
import TutorialReducer from './common/tutorial';
import YoutubeReducer from './common/youtube';
var rootReducer = combineReducers({
    //Model
    UserReducer: UserReducer,
    VersionReducer: VersionReducer,
    ModalYoutubeManualReducer: ModalYoutubeManualReducer,
    // Common
    RoutesReducer: RoutesReducer,
    SearchbarReducer: SearchbarReducer,
    TutorialReducer: TutorialReducer,
    YoutubeReducer: YoutubeReducer,
});
export default rootReducer;
