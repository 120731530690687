var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createAction, createReducer } from 'typesafe-actions';
var initialState = {
    version: '',
};
// UUID 확인, UUID 변경
export var CHANGE_VERSION = 'UuidReducer/CHANGE_VERSION';
export var changeVersion = createAction(CHANGE_VERSION)();
export var actions = { changeVersion: changeVersion };
var VersionReducer = createReducer(initialState, (_a = {},
    _a[CHANGE_VERSION] = function (state, action) {
        return __assign(__assign({}, state), action.payload);
    },
    _a));
export default VersionReducer;
