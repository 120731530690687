var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createAction, createReducer } from 'typesafe-actions';
var initialState = {
    update: false,
    value: '',
};
export var CLEAR_SEARCHBAR = 'SearchbarReducer/CLEAR_SEARCHBAR';
export var SET_SEARCHBAR = 'SearchbarReducer/SET_SEARCHBAR';
export var SET_VALUE = 'SearchbarReducer/SET_VALUE';
export var SET_UPDATE = 'SearchbarReducer/SET_UPDATE';
export var clearPath = createAction(CLEAR_SEARCHBAR)();
export var setSearchbar = createAction(SET_SEARCHBAR)();
export var setPath = createAction(SET_VALUE)();
export var setMoveTab = createAction(SET_UPDATE)();
export var actions = { setPath: setPath, setSearchbar: setSearchbar, clearPath: clearPath, setMoveTab: setMoveTab };
var SearchbarReducer = createReducer(initialState, (_a = {},
    _a[CLEAR_SEARCHBAR] = function () {
        return {
            update: false,
            value: '',
        };
    },
    _a[SET_SEARCHBAR] = function (state, action) {
        return {
            update: action.payload.update,
            value: action.payload.value,
        };
    },
    _a[SET_VALUE] = function (state, action) {
        return __assign(__assign({}, state), { value: action.payload.value });
    },
    _a[SET_UPDATE] = function (state, action) {
        return __assign(__assign({}, state), { update: action.payload.update });
    },
    _a));
export default SearchbarReducer;
