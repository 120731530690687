import { setLocaleDateString } from './tools';
export var account_sms_default = "[{\uD559\uC6D0\uBA85} \uAD50\uC721\uBE44 \uC548\uB0B4]\r\n\r\n\u25A0 \uC131\uBA85 : {\uD559\uC0DD\uBA85}\r\n\u25A0 \uCCAD\uAD6C\uB0B4\uC6A9 : {\uCCAD\uAD6C\uB0B4\uC6A9}\r\n\u25A0 \uCCAD\uAD6C\uC77C\uC790 : {\uCCAD\uAD6C\uC77C\uC790}\r\n\u25A0 \uCCAD\uAD6C\uAE08\uC561 : {\uCCAD\uAD6C\uAE08\uC561} \uC6D0\r\n\r\n\uBCC0\uD568\uC5C6\uB294 \uC0AC\uB791\uACFC \uAD00\uC2EC\uC744 \uBCF4\uB0B4\uC8FC\uC154\uC11C \uAC10\uC0AC\uB4DC\uB9BD\uB2C8\uB2E4.\r\n\uB354\uC6B1\uB354 \uBC1D\uC740 \uAD50\uC721\uC744 \uC704\uD574 \uD798\uC4F0\uACA0\uC2B5\uB2C8\uB2E4\u2665\r\n\r\n\u203B \uC218\uB0A9 \uC548\uB0B4 \uBA54\uC2DC\uC9C0\uB294 (\uD734\uC77C \uD3EC\uD568\uD558\uC5EC) \uC790\uB3D9 \uBC1C\uC1A1\uB418\uC624\uB2C8, \uC591\uD574 \uBD80\uD0C1\uB4DC\uB9BD\uB2C8\uB2E4.\r\n\r\n\u2192 \uD559\uC6D0\uBE44 \uACB0\uC81C : {\uACB0\uC81C\uB9C1\uD06C}";
export var makeAccountAlimContents = function (cname) {
    var today = new Date();
    var sevenDaysLater = new Date(today.setDate(today.getDate() + 6));
    return [
        "[".concat(cname, " \uAD50\uC721\uBE44 \uC548\uB0B4]\r\n"),
        '\r\n■ 성명 : 홍길동',
        '\r\n■ 청구내용 : 8월 분',
        "\r\n\u25A0 \uB0A9\uBD80\uAE30\uD55C : ~".concat(setLocaleDateString(sevenDaysLater, '. ')),
        '\r\n■ 청구금액 : 250,000원',
        '\r\n\r\n변함없는 사랑과 관심을 보내주시는 학부모님께 감사드립니다. 더욱더 나은 교육을 위해 힘쓰겠습니다 ♥',
        '\r\n\r\n※ 수납안내 메시지는 (휴일 포함) 자동 발송되니 양해 부탁드립니다 :)',
        '',
    ];
};
export var makeAccountAlimCostdayContents = function (cname) {
    return [
        "[".concat(cname, " \uAD50\uC721\uBE44 \uC548\uB0B4]\r\n"),
        '\r\n■ 성명 : 홍길동',
        '\r\n■ 청구내용 : 8월 분',
        "\r\n\u25A0 \uCCAD\uAD6C\uC77C\uC790 : ".concat(setLocaleDateString(null, '. ')),
        '\r\n■ 청구금액 : 250,000원',
        '\r\n\r\n변함없는 사랑과 관심을 보내주시는 학부모님께 감사드립니다. 더욱더 나은 교육을 위해 힘쓰겠습니다 ♥',
        '\r\n\r\n※ 수납안내 메시지는 (휴일 포함) 자동 발송되니 양해 부탁드립니다 :)',
        '',
    ];
};
