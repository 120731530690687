var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
var CustomModal = styled(Modal)(function (_a) {
    var theme = _a.theme;
    return ({
        '& > .MuiBox-root': {
            '&:focus-visible': {
                outline: 'none',
            },
        },
    });
});
var StyledBox = styled(Box)(function (props) { return ({
    overflow: 'hidden',
    position: 'absolute',
    top: props.isAlert ? '18.8%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    boxShadow: 'none',
}); });
function ModalDefault(props) {
    // const { setRootState } = useContext(ModalContext);
    var user_info = useSelector(function (state) { return state.UserReducer; });
    var visible = props.visible;
    var children = props.children;
    var onClose = props.onClose;
    var is_not_box_shadow = props.isNotBoxShadow;
    var props_opacity = props.opacity;
    var overflow = props.overflow;
    var container_overflow = props.containerOverflow;
    var sx = props.sx;
    var zIndex = props.zIndex;
    var isAccountSms = props.isAccountSms ? props.isAccountSms : false;
    var _a = useState(46), curOpacity = _a[0], setCurOpacity = _a[1];
    var _b = useState(false), overflowX = _b[0], setOverflowX = _b[1];
    useEffect(function () {
        var modals = document.getElementsByClassName('modal_default');
        if (visible) {
            setOpacity(modals.length + 1);
        }
        else {
            setOpacity(modals.length);
        }
        // setRootState(visible);
        setTimeout(function () {
            setModalScrollPosition();
        }, 0);
    }, [visible]);
    var setModalScrollPosition = function () {
        var modal_default = document.getElementById('modal_default');
        if (modal_default) {
            if (document.body.getBoundingClientRect().width < modal_default.scrollWidth) {
                setOverflowX(true);
                var gap = modal_default.scrollWidth - document.body.getBoundingClientRect().width;
                modal_default.scrollTo({
                    left: gap / 2,
                    behavior: 'smooth',
                });
            }
        }
    };
    var setOpacity = function (length) {
        var cur_opacity = 0;
        if (length == 1) {
            cur_opacity = props_opacity ? props_opacity : 46;
        }
        else {
            cur_opacity = 4 - length;
        }
        setCurOpacity(cur_opacity);
    };
    var onFocusModal = function (event) {
        event.currentTarget.blur();
    };
    return (_jsx(CustomModal, __assign({ open: visible, id: 'modal_default', className: 'modal_default', BackdropProps: {
            sx: {
                '&.MuiBackdrop-root:not(.MuiBackdrop-invisible)': {
                    opacity: props_opacity && props_opacity === 0 ? '0 !important' : '',
                },
                backgroundColor: "rgba(33, 33, 33, 0.".concat(props_opacity ? props_opacity : curOpacity, ")"),
                transition: '0.2s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms !important',
            },
        }, sx: {
            zIndex: zIndex !== null && zIndex !== void 0 ? zIndex : 1300,
            width: '100vw',
            height: '100vh',
            overflow: 'auto',
        } }, { children: _jsx(Box, __assign({ onClick: function () {
                !isAccountSms ? onClose() : null;
            }, sx: {
                zoom: props.zoomFalse ? '100%' : "".concat(user_info.zoom_status, "%"),
                width: '100%',
                height: '100%',
                position: 'relative',
                minWidth: overflowX ? '860px' : 'unset',
                minHeight: overflowX ? '500px' : 'unset',
            } }, { children: _jsx(StyledBox, __assign({ onClick: function (e) {
                    // e.preventDefault();
                    e.stopPropagation();
                }, "$is_not_box_shadow": is_not_box_shadow, id: 'modal_default_container', sx: sx ? sx : { overflow: container_overflow ? container_overflow : overflow ? 'unset' : 'unset' }, isAlert: props.isAlert }, { children: children }), void 0) }), void 0) }), void 0));
}
export default ModalDefault;
