var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import Box from '@mui/material/Box';
import ModalDefault from './ModalDefault';
import Button from '../button/Button';
import { ModalContext } from '../../provider/ModalProvider';
import { confirm_contents } from '../../utils/modal_contents';
import { StyledModalWrapper, StyledModalTitle, StyledModalUtil, StyledLine } from '../../styles/common';
import { styled } from '@mui/material';
export var ModalConfirmButton = styled(Button)(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: '1.075rem',
        width: 'calc(50% - 0.5px)',
        height: '100%',
    });
});
// "결제대기 조회 >" 를 위한 UI
var CheckPendingPayment = styled(Box)(function () { return ({
    position: 'absolute',
    right: '0.75rem',
    fontSize: '0.825rem',
    top: '0.35rem',
    color: '#969696',
    cursor: 'pointer',
    userSelect: 'none',
}); });
function ModalConfirm() {
    var modal_confirm = useContext(ModalContext).modal_confirm;
    var isSumMoney = modal_confirm.data.title === confirm_contents['sum_money'].title;
    var onCloseModal = function () {
        if (!isSumMoney) {
            modal_confirm.data.cancle_callback ? modal_confirm.data.cancle_callback() : false;
        }
        modal_confirm.closeModalConfirm();
    };
    function onButtonClick(text) {
        var cancle_arr = ['취소', '따로 발송', '아니요'];
        if (cancle_arr.includes(text)) {
            modal_confirm.data.cancle_callback ? modal_confirm.data.cancle_callback() : false;
            modal_confirm.closeModalConfirm();
        }
        else {
            modal_confirm.data.confirm_callback();
            modal_confirm.closeModalConfirm();
        }
    }
    var onClickWaitPyament = function () {
        modal_confirm.data.payment_callback ? modal_confirm.data.payment_callback() : false;
    };
    return (_jsx(_Fragment, { children: _jsx(ModalDefault, __assign({ visible: modal_confirm.data.visible, onClose: onCloseModal }, { children: _jsxs(StyledModalWrapper, { children: [_jsx(StyledModalTitle, { dangerouslySetInnerHTML: { __html: modal_confirm.data.title } }, void 0), _jsxs(StyledModalUtil, { children: [_jsx(ModalConfirmButton, __assign({ color: modal_confirm.data.button_type == 'normal_reverse_color' ? 'gray_1' : 'green', onClick: function (e) {
                                    e.stopPropagation();
                                    onButtonClick(modal_confirm.data.left);
                                } }, { children: modal_confirm.data.left }), void 0), _jsx(StyledLine, {}, void 0), _jsx(ModalConfirmButton, __assign({ color: modal_confirm.data.button_type == 'normal_reverse_color' ? 'green' : 'gray_1', onClick: function () { return onButtonClick(modal_confirm.data.right); } }, { children: modal_confirm.data.right }), void 0)] }, void 0), isSumMoney ? (_jsx(CheckPendingPayment, __assign({ onClick: onClickWaitPyament }, { children: '결제대기 조회 >' }), void 0)) : null] }, void 0) }), void 0) }, void 0));
}
export default ModalConfirm;
