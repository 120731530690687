var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import ModalDefault from './ModalDefault';
import Button from '../button/Button';
import { ModalContext } from '../../provider/ModalProvider';
import { StyledModalWrapper, StyledModalTitle, StyledModalUtil } from '../../styles/common';
function ModalCheck() {
    var modal_check = useContext(ModalContext).modal_check;
    return (_jsx(_Fragment, { children: _jsx(ModalDefault, __assign({ visible: modal_check.data.visible, onClose: function () { return modal_check.closeModalCheck(); } }, { children: _jsxs(StyledModalWrapper, { children: [_jsx(StyledModalTitle, { dangerouslySetInnerHTML: { __html: modal_check.data.title } }, void 0), _jsx(StyledModalUtil, { children: _jsx(Button, __assign({ color: 'green', onClick: function () { return modal_check.closeModalCheck(); }, sx: { width: 1, height: 1, fontSize: '1.2rem' } }, { children: "\uD655\uC778" }), void 0) }, void 0)] }, void 0) }), void 0) }, void 0));
}
export default ModalCheck;
