var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ModalCustomDefault from './ModalCustomDefault';
import { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { styled, Box } from '@mui/material';
import InputNormal from '../input/InputNormal';
import validate from '../../utils/validation';
import Button from '../button/Button';
var ContentsBox = styled(Box)(function (props) { return ({
    width: '28rem',
    height: '6.5rem',
    padding: '1rem',
    backgroundColor: props.theme.palette.white.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '0.25rem',
    p: {
        fontWeight: 'bold',
    },
    '& > div': {
        width: '92%',
        margin: '0.325rem auto 0 auto',
        '& > p.MuiFormHelperText-root': {
            width: '100%',
            top: '3rem',
            fontSize: '0.35rem',
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'auto',
        },
    },
    '.MuiOutlinedInput-root': {
        padding: 0,
        '.password_icon': {
            width: '1.2rem',
            height: '1.2rem',
            right: '0.75rem',
        },
    },
    input: {
        backgroundColor: '#f7f7f7',
        textAlign: 'center',
    },
    fieldset: {
        border: 'none',
    },
}); });
var CustomHeader = styled(Box)(function (props) { return ({
    width: '93%',
    margin: 'auto',
    height: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: props.theme.palette.white.main,
    padding: '1.5rem 0',
    borderBottom: '1px solid #dfdfdf ',
    fontSize: '1rem',
    position: 'relative',
    marginBottom: '-1px',
    svg: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '0',
        width: '1.3rem',
        height: '1.3rem',
        color: props.theme.palette.gray_1.main,
        cursor: 'pointer',
    },
}); });
function ModalCheckPW(props) {
    var visible = props.visible;
    var onClose = props.onClose;
    var onSubmit = props.onSubmit;
    var _a = useState({
        value: '',
        type: 'password',
        vali_type: 'password',
        error: false,
        error_text: '4~16자 영문 소문자, 대문자, 숫자, 특수문자 등 으로 조합하세요.',
    }), passwordObj = _a[0], setPasswordObj = _a[1];
    useEffect(function () {
        if (!visible) {
            setPasswordObj({
                value: '',
                type: 'password',
                vali_type: 'password',
                error: false,
                error_text: '4~16자 영문 소문자, 대문자, 숫자, 특수문자 등 으로 조합하세요.',
            });
        }
    }, [visible]);
    var handlePassword = function (e) {
        var new_value = e.target.value.trim() === '' ? '' : e.target.value;
        passwordObj.value = new_value;
        var vali_res = validate[passwordObj.vali_type](e.target.value);
        passwordObj.error = !vali_res;
        setPasswordObj(__assign({}, passwordObj));
    };
    var checkCode = function (e) {
        if (e.code == 'Enter') {
            onSubmit(passwordObj.value);
        }
    };
    return (_jsxs(ModalCustomDefault, __assign({ title: '\uBE44\uBC00\uBC88\uD638 \uD655\uC778', visible: visible, onClose: onClose, header: false, overflow: false }, { children: [_jsxs(CustomHeader, { children: [_jsx(Box, { children: "\uC554\uD638 \uC785\uB825" }, void 0), _jsx(IoMdClose, { onClick: onClose }, void 0)] }, void 0), _jsx(ContentsBox, { children: _jsx(InputNormal, { type: passwordObj.type, value: passwordObj.value, 
                    // error={passwordObj.error}
                    // errorText={passwordObj.error_text}
                    onChange: handlePassword, onKeyDown: checkCode, usePasswordIcon: false }, void 0) }, void 0), _jsx(Button, __assign({ variant: 'contained', sx: { width: '100%', borderRadius: 0, height: '3rem' }, color: 'green', onClick: function () { return onSubmit(passwordObj.value); } }, { children: "\uD655\uC778" }), void 0)] }), void 0));
}
export default ModalCheckPW;
