var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, createContext } from 'react';
// ========================================================================================
// 초기값 설정
export var TableContext = createContext({
    table_data: {},
    clearTableData: function () {
        return;
    },
    clearTargetTableData: function (data_key) {
        return;
    },
    setSavedTableIdx: function (data_key, idx) {
        return;
    },
});
// ========================================================================================
// Provider 설정
function TableProvider(props) {
    var children = props.children;
    var _a = useState({}), tableData = _a[0], setTableData = _a[1];
    // 모달 컨트롤러
    var TableController = {
        table_data: tableData,
        clearTableData: function () {
            setTableData({});
            return;
        },
        clearTargetTableData: function (data_key) {
            setTableData(function (state) {
                var _a;
                return __assign(__assign({}, state), (_a = {}, _a[data_key] = __assign(__assign({}, state[data_key]), { checked_idx: [], checked_cnt: 0 }), _a));
            });
            return;
        },
        setSavedTableIdx: function (data_key, idx) {
            setTableData(function (state) {
                var _a;
                return __assign(__assign({}, state), (_a = {}, _a[data_key] = __assign(__assign({}, state[data_key]), { checked_cnt: idx.length, checked_idx: __spreadArray([], idx, true) }), _a));
            });
            return;
        },
    };
    return (_jsx(_Fragment, { children: _jsx(TableContext.Provider, __assign({ value: TableController }, { children: children }), void 0) }, void 0));
}
export default TableProvider;
