var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ModalDefault from './ModalDefault';
import { IoCloseSharp } from 'react-icons/io5';
import { d_flex_center, d_flex_space_between } from '../../styles/common';
import { styled, Box, Typography } from '@mui/material';
var StyledIconWrap = styled(Box)(function (props) { return (__assign({ width: '2rem', height: '2rem', borderRadius: '50%', cursor: 'pointer', backgroundColor: props.theme.palette.white.main, color: props.theme.palette.red.main, svg: {
        width: '1.7rem',
        height: '1.7rem',
        strokeWidth: 30,
        marginRight: '-1px',
    } }, d_flex_center)); });
var StyledModalCustom = styled(Box)(function (props) { return ({
    height: 'auto',
    borderRadius: '6px',
    overflow: props.containerOverflow ? props.containerOverflow : props.isOverflow ? 'visible' : 'unset',
    backgroundColor: props.theme.palette.white.main,
}); });
var StyledHeader = styled(Box)(function (props) { return (__assign(__assign({ width: props.headerWidth ? props.headerWidth : '100%', height: props.height ? props.height : '3.5rem', padding: '0 1rem', fontSize: '1.1rem', 
    // backgroundColor: '#f12211',
    background: 'linear-gradient(0deg, rgba(242,36,19,1) 0%, rgba(255,85,58,1) 100%)', color: props.theme.palette.white.main }, d_flex_space_between), { '&.message_format': {
        justifyContent: 'flex-end',
        position: 'relative',
        height: '4rem',
        span: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            fontWeight: 400,
            b: {
                fontSize: '1.1rem',
                fontWeight: 600,
            },
        },
    } })); });
function ModalCustomDefault(props) {
    var visible = props.visible;
    var title = props.title;
    var children = props.children;
    var onClose = props.onClose;
    var height = props.height;
    var overflow = props.overflow;
    var header = props.header;
    var use_blur = props.useBlur;
    var container_overflow = props.containerOverflow;
    var header_width = props.headerWidth;
    var sub_title = props.subTitle;
    var use_header_button = props.useHeaderButton;
    var z_index = props.zIndex;
    var position = props.position;
    var isAccountSms = props.isAccountSms ? props.isAccountSms : false;
    var doNotBorder = props.doNotBorder;
    var message_format = title == '메시지 발송 형식';
    var ModalCustomHeader = function () {
        return (_jsxs(_Fragment, { children: [message_format ? (_jsxs(Box, __assign({ component: 'span' }, { children: [_jsx(Typography, __assign({ component: 'b' }, { children: "\uBA54\uC2DC\uC9C0 \uBC1C\uC1A1 \uD615\uC2DD" }), void 0), "\uC744 \uC120\uD0DD\uD574\uC8FC\uC138\uC694!"] }), void 0)) : (_jsxs(Box, __assign({ component: 'span' }, { children: [title, sub_title ? (_jsx(Box, __assign({ component: 'span', sx: { fontSize: '0.875rem', marginLeft: '0.25rem' } }, { children: sub_title }), void 0)) : null] }), void 0)), use_header_button !== false ? (_jsx(StyledIconWrap, __assign({ onClick: onClose }, { children: _jsx(IoCloseSharp, { onClick: onClose }, void 0) }), void 0)) : null] }, void 0));
    };
    return (_jsx(_Fragment, { children: _jsx(ModalDefault, __assign({ visible: visible, onClose: function () {
                if (use_blur !== false) {
                    onClose();
                }
            }, overflow: overflow, containerOverflow: container_overflow, zIndex: z_index, sx: {
                borderRadius: message_format ? '1rem' : doNotBorder ? '' : '6px',
            }, isAccountSms: isAccountSms }, { children: _jsxs(StyledModalCustom, __assign({ isOverflow: overflow, containerOverflow: container_overflow }, { children: [header !== false ? (_jsx(StyledHeader, __assign({ height: height, headerWidth: header_width, className: message_format ? 'message_format' : '', sx: { borderRadius: doNotBorder ? '6px 6px 0 0' : '' } }, { children: _jsx(ModalCustomHeader, {}, void 0) }), void 0)) : null, _jsx(Box, __assign({ sx: { position: position || '' } }, { children: children }), void 0)] }), void 0) }), void 0) }, void 0));
}
export default ModalCustomDefault;
