import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import './assets/scss/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
// sentry 적용
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
// const console_options = process.env.NODE_ENV === 'development' ? { console: false } : {};
// const app_name =
//   process.env.NODE_ENV === 'development' ? process.env.REACT_APP_NAME_TEST : process.env.REACT_APP_NAME_REAL;
// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     // real
//     dsn: 'https://061deace6e244334bdecdde9c4b3ef37@o4504003601301504.ingest.sentry.io/4504013505363968',
//     // test
//     // dsn: 'https://f1a405298d144c8fa73dbbaacc1447ed@o4504003601301504.ingest.sentry.io/4504013609107456',
//     integrations: [new BrowserTracing(), new Sentry.Integrations.Breadcrumbs(console_options)],
//     tracesSampleRate: 0.0,
//     release: `${app_name}@${process.env.VERSION}`,
//     environment: process.env.NODE_ENV,
//     // localhost 에서 error message 발송 하지 않도록 만든 옵션
//     beforeSend: event => {
//       if (window.location.hostname === 'localhost') {
//         return null;
//       }
//       return event;
//     },
//   });
// }
ReactDOM.render(_jsx(RecoilRoot, { children: _jsx(BrowserRouter, { children: _jsx(App, {}, void 0) }, void 0) }, void 0), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
